import { Typography, Box } from "@mui/material";
import {
  PieChart,
  Pie,
  Label,
  Tooltip,
  Legend,
  Cell,
  LabelList,
  ResponsiveContainer,
} from "recharts";
import { pink, blue } from "@mui/material/colors";
import CustomTooltip from "../tooltip/customTooltip";

export default function SumChart({ data, label = "label" }) {
  const dataArray = Object.values(data);

  const duration = 500;
  const colors = [
    blue[900],
    blue[700],
    blue[500],
    blue[300],
    blue[100],
    blue[50],
  ];

  return (
    <>
      <ResponsiveContainer width="95%" height="100%">
        <PieChart>
          <Pie
            data={dataArray}
            dataKey="amount"
            nameKey="type"
            cx="50%"
            cy="50%"
            innerRadius={75}
            outerRadius={125}
            fill="#82ca9d"
            animationDuration={duration}
            strokeWidth={0}
          >
            <Label width={100} position="center">
              {label}
            </Label>
            {dataArray.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={colors[index % colors.length]}
              />
            ))}
          </Pie>
          <Tooltip
            content={<CustomTooltip active={false} payload={[]} label={label} />}
          />
          <Legend
            layout="vertical"
            align="right"
            verticalAlign="middle"
            iconType="diamond"
          />
        </PieChart>
      </ResponsiveContainer>
    </>
  );
}
