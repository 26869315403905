import SumChart from "../../charts/charts/sumChart";
import { useData } from "../../../utils/firestore-database/providers/dataProvider";
import SumTable from "../../table/property-table/sumTable";
import { Stack, Grid, Paper, Button } from "@mui/material";
import SumCard from "../../card/sumCard/sumCard";
import { Link } from "react-router-dom";

export default function GlobalPatrimoineDisplay() {
  const { sumData } = useData();

  const dataArray = Object.values(sumData);
  const initialValue = 0;
  const sum = dataArray.reduce(
    (accumulator, currentvalue) => accumulator + currentvalue.amount,
    initialValue
  );

  return (
    <Grid container spacing={3}>
      <Grid item md={8} lg={6}>
        <Paper
          sx={{
            // display: { xs: 'block' }
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: 300,
          }}
        >
          <SumChart data={sumData} label="Votre Patrimoine" />
        </Paper>
      </Grid>
      <Grid item xs={12} md={4} lg={3}>
        {/* <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: 300,
          }}
        > */}
        <Stack direction="column" spacing={2}>
          <SumCard sum={sum} title="Votre patrimoine total" />
          <Button
            sx={{ padding: "55px 20px" }}
            variant="contained"
            component={Link}
            to={"../addGlobalProperty"}
          >
            Ajouter
          </Button>
        </Stack>
        {/* </Paper> */}
      </Grid>

      <Grid item xs={12} md={12} lg={12}>
        <SumTable data={dataArray} sum={sum} />
      </Grid>
    </Grid>
  );
}
