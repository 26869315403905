import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { useNavigate, Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAuth } from "../../utils/firestore-database/providers/authProvider";
import GoogleButton from "../../components/button/googleButton";
import LinkedInButton from "../../components/button/linkedInButton";
import { Container } from "@mui/material";

// TODO remove, this demo shouldn't need to reset the theme.

export default function SignUpPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  const {
    currentUser,
    loading,
    CreationWithEmail,
    signInWithGoogle,
    signUpErrorCode,
    setSignUpErrorCode,
  } = useAuth();

  useEffect(() => {
    if (currentUser) {
      navigate("/");
    }
  }, [currentUser, navigate]);

  useEffect(() => {
    if (signUpErrorCode) {
      setSignUpErrorCode("");
    }
  }, []);

  function handleSubmit(event) {
    event.preventDefault();
    CreationWithEmail(email, password);
  }

  function errorMessageDisplay(errorCode) {
    switch (errorCode) {
      case "auth/invalid-email":
        return "Email Invalide";
      case "auth/missing-password":
        return "Veuillez créer un mot de passe";
      case "auth/email-already-in-use":
        return "Un compte existe déjà avec cette adresse mail";
      case "auth/weak-password":
        return "Le mot de passe doit contenir au minimum 6 caractères";
      default:
        return "Un problème est survenu ";
    }
  }

  return (
    <Container>
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1 }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Créer un Compte
        </Typography>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={1}>
            {/* <Grid item xs={12} sm={6}>
            <TextField
              autoComplete="given-name"
              name="firstName"
              //   required
              fullWidth
              id="firstName"
              label="Nom"
              autoFocus
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              //   required
              fullWidth
              id="lastName"
              label="Last Name"
              name="Prénom"
              autoComplete="family-name"
              onChange={(e) => setSurname(e.target.value)}
            />
          </Grid> */}
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="new-password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              {/* <FormControlLabel
              control={<Checkbox value="allowExtraEmails" color="primary" />}
              label="I want to receive inspiration, marketing promotions and updates via email."
            /> */}
            </Grid>

            <Grid item xs={12}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Créer un compte
              </Button>
            </Grid>
            {signUpErrorCode && (
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  sx={{
                    color: "error.main",
                  }}
                >
                  {errorMessageDisplay(signUpErrorCode)}
                </Typography>
              </Grid>
            )}

            <Grid item xs={12}>
              <GoogleButton onClick={signInWithGoogle} />
            </Grid>
            {/* <Grid item xs={12} md={6}>
              <LinkedInButton />
            </Grid> */}
          </Grid>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link to="/signIn" variant="body2">
                Vous avez déjà un compte ? connectez vous
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}
