import { CardContent, Typography, Card, Button } from "@mui/material";

export default function CardEntreprise() {
    return (
        <>
            <Card>
                <CardContent>
                    <Typography variant="h4"> L'entreprise </Typography>
                    <Typography variant="body1">Nom ou dénomination sociale : ISONOS</Typography>
                    <Typography variant="body1">Adresse professionnelle ou siège social : 5 allée de l'île tabor, 44100 Nante </Typography>
                    <Typography variant="body1">SIRET: 844 469 221 00023</Typography>
                    <Typography variant="body1">NAF/APE : 6619A </Typography>
                </CardContent>
            </Card>

        </>
    )
}