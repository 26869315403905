import {
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@mui/material";

export default function SelectIsChild({ value, handleChange }) {
  return (
    <FormControl>
      <FormLabel id="select-child">Avez vous des enfants ?</FormLabel>
      <RadioGroup
        row
        aria-labelledby="select-child"
        defaultValue="female"
        name="radio-buttons-group"
        value={value || ""}
        onChange={handleChange}
      >
        <FormControlLabel value="yes" control={<Radio />} label="Oui" />
        <FormControlLabel value="no" control={<Radio />} label="Non" />
      </RadioGroup>
    </FormControl>
  );
}
