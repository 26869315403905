import { DialogActions, Dialog, DialogContent, DialogTitle, DialogContentText, Alert, InputLabel, FormControl, Select, MenuItem, TableHead, TableBody, Table, TableCell, TableContainer, TableRow, Button, Container, TextField, Typography, Box, Paper, Toolbar, Grid, ListItemText, List, ListItem, Stack, Switch, Tooltip, Icon, IconButton } from "@mui/material";
import { Theme } from "../utils/theme";
import styled from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useState } from "react";
import dayjs from "dayjs";
import AgeCalculator from "../utils/calculator/ageCalulator";
import SelectQualityDonation from "../components/select/selectQualityDonation";
import { DroitDuConjoint, DroitEnfants, Qd, ResteQd } from "../utils/successionReserveEtPartage";
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import { DMTG } from "../utils/dmtg";
import { CalculImputationQd, CalculImputationReserve, CalculDonationReserve, TotalDonation } from "../utils/calculDonation";
import DialogConseil from "../utils/conseil";


export default function Succession() {
    const donationDataStorage = localStorage.getItem('donationDataStorage')
    const donationDataStorageArray = donationDataStorage !== null ? JSON.parse(donationDataStorage) : [{ fname: "", amount: "0", quality: "", date: "" }]

    // const [data, setData]= useState([{fname:"", amount:"0", quality:"", date:""}])

    const [donationData, setDonationData] = useState(donationDataStorageArray)

    const [qualityQonataire, setQualityDonataire] = useState('');
    const [time, setTime] = useState(null)
    const [isChecked, setIsChecked] = useState(false)
    const [isOtherChildChecked, setIsOtherChildChecked] = useState(false)
    const [isUfChecked, setIsUfChecked] = useState(false)
    const [ageConjoint, setAgeConjoint] = useState('')

    const personData = localStorage.getItem('personData')
    const personDataArray = personData !== null ? JSON.parse(personData) : ""



    const patrimData = localStorage.getItem('patrimData')
    const patrimDataArray = patrimData !== null ? JSON.parse(patrimData) : []

    const data666 = patrimDataArray.filter((object) =>
        object.valeur > 0
    )

    const totalPatrimfilter = data666.map(object =>
        parseInt(object.valeur)
    )

    const totalPatrim = totalPatrimfilter.reduce(
        (acc, value) => acc + value, 0
    )


    const handleClick = () => {
        setDonationData([...donationData, { fname: "", amount: "", quality: "", date: "" }])
    }

    const handleChange = (e, i) => {
        const { name, value } = e.target
        const onchangeVal = [...donationData]
        onchangeVal[i][name] = value
        setDonationData(onchangeVal)
    }

    const handleDelete = (i) => {
        const deleteVal = [...donationData]
        deleteVal.splice(i, 1)
        setDonationData(deleteVal)
    }

    function setDonationDataToStorage() {
        // console.log(data)
        localStorage.setItem('donationDataStorage', JSON.stringify(donationData))
        console.log(localStorage);

    }


    const qd = Qd(parseInt(personDataArray.childNumber), 0, totalPatrim, false, TotalDonation({ donationData }))

    const qdRestante = ResteQd(qd, CalculImputationQd({ donationData }))

    const donationChild = CalculImputationReserve({ donationData })

    const droitDuConjointData = personDataArray.maritalStatus === 'mariage' ? DroitDuConjoint(totalPatrim, isUfChecked, ageConjoint, qdRestante) : 0

    const droitDuConjoint = droitDuConjointData >= 0 ? droitDuConjointData : 0

    // const resteEnfants = personDataArray.maritalStatus === 'mariage' ? 
    // (totalPatrim-droitDuConjoint)/parseInt(personDataArray.child) : totalPatrim/parseInt(personDataArray.child)

    const resteEnfants = DroitEnfants("name", parseInt(personDataArray.childNumber), totalPatrim, qd, droitDuConjoint, donationChild)


    //   function tableSuccession(){
    //     const tab = []

    //     if(personDataArray.maritalStatus === "mariage"){
    //     tab.push({name:"Conjoint", value:droitDuConjoint})
    //     }

    //     for(let i=0; i<parseInt(personDataArray.child); i++){
    //         //value sera resteEnfants[i] vu qu'on va faire un Array
    //         const object = {name:`enfant n°${i + 1}`, value:resteEnfants}

    //         tab.push(object)
    //     }

    //     return tab
    //   }



    //   const droitEnfant = donationChild.map(object=>
    //     {name:object.name, value:DroitEnfants(object.name, personDataArray.child, totalPatrim, qd, droitDuConjoint)}

    //     )




    function tableSuccession() {
        const tab = []

        if (personDataArray.maritalStatus === "mariage") {
            tab.push({ name: "Conjoint", value: droitDuConjoint })
        }

        for (let i = 0; i < parseInt(personDataArray.childNumber); i++) {
            //value sera resteEnfants[i] vu qu'on va faire un Array
            const object = { name: `enfant n°${i + 1}`, value: resteEnfants }
            tab.push(object)
        }

        return tab
    }

    const arrayTotalSuccession = tableSuccession().map(object => {
        if (object.name === "Conjoint") {
            return {
                name: object.name,
                value: object.value
            }
        } else {
            return {
                name: object.name,
                value: DroitEnfants(object.name, personDataArray.childNumber, totalPatrim, droitDuConjoint, donationChild)
            }
        }
    })



    function handleOtherChildSwitch(e) {
        const isChecked = e.target.checked

        if (isChecked) {
            setIsOtherChildChecked(true)
            setIsUfChecked(false)
        } else {
            setIsOtherChildChecked(false)
        }
    }

    const dmtg = DMTG(resteEnfants, "")



    function AlertTest() {
        return
    }

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };



    // Y A UN PROBLEME QUAND CA CHANGE POUR ENFANT, en fait ça prend le name qu'il y avait juste avant (undefined en général, sauf s'il y avait déjà un truc dans le champ) à voir
    return (
        <>
            <Container>
                <Typography variant="h2"> C'est la successerie</Typography>
                <Paper elevation={1}
                    sx={{
                        p: 2
                    }} >
                    <Typography>Avez-vous effectué des donations ?</Typography>
                    <Stack direction="row" alignItems="center">
                        <Typography>non</Typography>
                        <Switch onChange={e => setIsChecked(e.target.checked)} />
                        <Typography>oui</Typography>
                    </Stack>
                    <Grid container spacing={1}>
                        {isChecked ? donationData.map((val, i) =>
                            <>
                                <Grid item xs={3}>
                                    {/* <SelectQualityDonation name="quality" value={val.quality} qualityDonataire={val.quality} setQualityDonataire={(e)=>handleChange(e,i)} /> */}
                                    <SelectQualityDonation name="quality" value={val.quality} onChange={(e) => handleChange(e, i)} />
                                </Grid>
                                {val.quality === "child" ?
                                    <Grid item xs={3}>
                                        <FormControl sx={{ m: 0, width: '100%' }}>
                                            <InputLabel id="demo-simple-select-helper-label">Enfant</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-helper-label"
                                                name="fname"
                                                id="demo-simple-select"
                                                value={val.fname}
                                                label="SituationMaritale"
                                                autoWidth
                                                size="small"
                                                onChange={(e) => handleChange(e, i)}>
                                                {tableSuccession().map((item) =>
                                                    <MenuItem key={item.name} value={item.name}>
                                                        {item.name}
                                                    </MenuItem>)}
                                            </Select>
                                        </FormControl>
                                    </Grid> :
                                    <Grid item xs={3}>
                                        <TextField size="small" name="fname" value={val.fname} id="name" label="Nom" variant="outlined" onChange={(e) => handleChange(e, i)} />
                                    </Grid>}
                                {/* <Grid item xs={3}>
                            <TextField size="small" name="fname" value={val.fname} id="name" label="Nom" variant="outlined" onChange={(e)=>handleChange(e,i)}/>
                        </Grid> */}
                                <Grid item xs={2}>
                                    <TextField size="small" id="amount" name="amount" value={val.amount} label="Montant" variant="outlined" onChange={(e) => handleChange(e, i)} />
                                </Grid>

                                <Grid item xs={3}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker label="Date de la donation" value={time} sx={{
                                            fontSize: '0.875rem', // Taille de la police réduite
                                            p: 0,
                                        }} />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={1}>
                                    <Button onClick={() => handleDelete(i)}>-</Button>
                                </Grid>
                            </>
                        ) : ''
                        }
                    </Grid>
                    {isChecked ?
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}>
                            <Button onClick={setDonationDataToStorage}>Enregistrer</Button>
                            <Button onClick={handleClick}>+</Button>
                        </Box> : ''}



                </Paper>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    mt: 1
                }}
                >
                    <Paper sx={{
                        p: 2
                    }}>
                        <Typography variant="h4">Résumé Patrimoniale</Typography>
                        <Typography>Vous êtes {personDataArray.maritalStatus} et avez {personDataArray.childNumber} enfants </Typography>
                        <List dense>
                            {data666.map(object =>
                                <ListItem>
                                    <ListItemText
                                        primary={object.label}
                                        secondary={`${parseInt(object.valeur).toLocaleString()} €`} />
                                </ListItem>)
                            }
                            <ListItem>
                                <ListItemText
                                    primary='TOTAL'
                                    secondary={`${totalPatrim.toLocaleString()} €`} />
                            </ListItem>
                        </List>
                        {personDataArray.maritalStatus === 'mariage' &&
                            <>
                                <Stack direction='row' alignItems='center'>
                                    <Typography>Avez-vous un enfant d'un autre lit ?</Typography>
                                    <Tooltip title="
                        Pourquoi est-ce important ? parce que les droits de votre conjoint ne seront pas les même en cas d'enfant non commun. En effet, celui-ci ne pourra pas choisir l'option 100% en usufruit">
                                        <IconButton>
                                            <LiveHelpIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Stack>
                                <Stack direction="row" alignItems="center">
                                    <Typography>non</Typography>
                                    <Switch onChange={handleOtherChildSwitch} />
                                    <Typography>oui</Typography>
                                </Stack>
                            </>
                        }
                        {!isOtherChildChecked && personDataArray.maritalStatus === 'mariage' ?
                            <>
                                <Stack direction='row' alignItems='center'>
                                    <Typography>Est-ce que votre conjoint souhaite exercer l'option 100% en usufruit ?</Typography>
                                    <Tooltip title={
                                        <a href="https://finvesteco.fr/" target="_blank" rel="noreferrer">
                                            Qu'est ce que l'usufruit ? </a>} >
                                        <IconButton>
                                            <LiveHelpIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Stack>
                                <Stack direction="row" alignItems="center">
                                    <Typography>non</Typography>
                                    <Switch onChange={e => setIsUfChecked(e.target.checked)} />
                                    <Typography>oui</Typography>
                                </Stack>
                            </> : ''}
                        {isUfChecked &&
                            <TextField
                                label="Age du conjoint"
                                size="small"
                                onChange={e => setAgeConjoint(e.target.value)}
                            />}
                    </Paper>
                    <Paper sx={{
                        p: 2
                    }}>
                        <Typography variant="h4">Assurance Vie</Typography>
                    </Paper>
                </Box>
                <Toolbar />
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 400 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Nom</TableCell>
                                <TableCell align="right">Valeur Reçue</TableCell>
                                <TableCell align="right">DMTG</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {/* {tableSuccession().map((row) => ( */}
                            {arrayTotalSuccession.map((row) => (
                                <TableRow
                                    key={row.name}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.name}
                                    </TableCell>
                                    <TableCell align="right">
                                        {`${row.value.toLocaleString()} €`}
                                    </TableCell>
                                    <TableCell align="right">
                                        {row.name === "Conjoint" ? "0 €" : `${dmtg.toLocaleString()} €`}
                                    </TableCell>
                                    {/* <TableCell align="right">{row.fat}</TableCell>    */}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Typography>{qd}</Typography>
                {/* <Button onClick={e=>console.log(tableSuccession())}>oui</Button>
    <Button onClick={e=>console.log({arrayTotalSuccession})}>qd</Button> */}
                <Button onClick={handleClickOpen}>Un conseil ?</Button>

                <DialogConseil open={open} handleClose={handleClose} totalPatrim={totalPatrim} />
            </Container>
        </>
    );
}