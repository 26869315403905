import { Button, Box, Grid, Paper, CircularProgress } from "@mui/material";
import { useState } from "react";
import SpouseInput from "../../input/personnal-information-inputs/spouseInput";
import CustomerInfo from "../../input/personnal-information-inputs/customerInfo";
import { useEffect } from "react";
import SetChild from "../../input/personnal-information-inputs/setChild";
import { useData } from "../../../utils/firestore-database/providers/dataProvider";
// import { identite } from "../utils/GlobalTheme/Data/userData";

export default function PersonnalInformationDisplay() {
  const { personnalData, personnalDataLoading, putDataInFirebase } = useData();

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setData(personnalData);
    setLoading(personnalDataLoading);
  }, [personnalData, personnalDataLoading]);

  function handleSubmit(e) {
    e.preventDefault();
    putDataInFirebase(data, "data", "personnal", "perso");
  }

  //ATTEEEEENNNNNTTTTTTIIIIIIIIIIOOOOOOOONNNNNN pour le premier appel du storage. S'il n'y a pas de storage ça leve une erreur, il faut donc un if ou un try catch
  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : (
        <Box component="form" onSubmit={handleSubmit}>
          <Paper sx={{ p: 2 }}>
            <Grid container spacing={2}>
              <CustomerInfo data={data} setData={setData} loading={loading} />

              {(data.maritalStatus === "mariage" ||
                data.maritalStatus === "pacs") && (
                <SpouseInput data={data} setData={setData} />
              )}

              {data.isChild === "yes" && (
                <SetChild data={data} setData={setData} />
              )}
              <Grid item xs={12} container justifyContent="flex-end">
                <Button
                  variant="contained"
                  type="submit"
                  sx={{ padding: "30px 20px" }}
                >
                  Enregistrer
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      )}
    </>
  );
}
