import {
    TableContainer,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableBody,
    Paper,
    Typography,
    Divider,
    Button,
    Box,
} from "@mui/material";
import { blue } from "@mui/material/colors";
import { Link } from "react-router-dom";

export default function EstateTable({ data, title, link }) {
    const initialValue = 0;
    const sum = data.reduce(
        (accumulator, currentvalue) => accumulator + currentvalue.amount,
        initialValue
    );

    return (
        <>
            {sum > 0 && (
                <>
                    {/* <Divider /> */}
                    <Typography variant="h5" sx={{ mt: 1 }} gutterBottom>
                        {title}
                    </Typography>
                    <TableContainer component={Paper} sx={{ mt: 1 }}>
                        <Table
                            sx={{ minWidth: 400 }}
                            size="small"
                            aria-label={`Tableau de ${title}`}
                        >
                            <TableHead>
                                <TableRow
                                    sx={{
                                        "&:last-child td, &:last-child th": { border: 0 },
                                        bgcolor: blue[100],
                                    }}
                                >
                                    <TableCell>Type</TableCell>
                                    <TableCell align="right">Mode de détention</TableCell>
                                    <TableCell align="right">Montant</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.map((row, index) => (
                                    <TableRow
                                        key={`row-${index}`}
                                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {row.type}
                                        </TableCell>

                                        <TableCell align="right">{row.locationType || row.investType}</TableCell>
                                        <TableCell align="right">{`${row.amount.toLocaleString()} €`}</TableCell>
                                    </TableRow>
                                ))}
                                <TableRow
                                    sx={{
                                        "&:last-child td, &:last-child th": { border: 0 },
                                        bgcolor: blue[300],
                                    }}
                                >
                                    <TableCell component="th" scope="row">
                                        Total
                                    </TableCell>
                                    <TableCell align="right"></TableCell>
                                    <TableCell align="right">{`${sum.toLocaleString()} €`}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box container justifyContent="flex-end" sx={{ mt: 1 }}>
                        <Button variant="outlined" component={Link} to={link}>
                            Ajouter
                        </Button>
                    </Box>
                </>
            )}
        </>
    );
}
