import { Password } from "@mui/icons-material";
import { Typography, Button, TextField, Toolbar } from "@mui/material";
import { initializeApp } from "firebase/app";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup, signOut, onAuthStateChanged, signInWithRedirect } from "firebase/auth";
import firebaseui from "firebaseui";
import { useState } from "react";
import { useAuth } from "../utils/firestore-database/providers/authProvider";
import MainAppBar from "../components/mainAppBar";



export default function Connexion() {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [usern, setUser] = useState("")


  // onAuthStateChanged(auth, (user) => {
  //   if (user) {
  //     // User is signed in, see docs for a list of available properties
  //     // https://firebase.google.com/docs/reference/js/auth.user
  //     const uid = user.uid;
  //     setUser(user)
  //     // ...
  //   } else {
  //     // User is signed out
  //     // ...
  //   }
  // });

  const { currentUser, loading, LoginWithEmail, CreationWithEmail, signInWithGoogle, Logout } = useAuth();

  function handleCreationWithEmailClick() {
    CreationWithEmail(email, password)
  }

  function handleLoginClick() {
    LoginWithEmail(email, password)
  }

  function handleLogoutClick() {
    Logout()
  }



  //IL FAUDRAIT FAIRE UN TRUC AVEC LE ROUTER ET LA CONNECTION MAIS LA FLEMME POUR L'INSTANT

  return (
    <div>

      <Toolbar />
      {!currentUser ? <Typography variant="h5">Connecterie</Typography>
        : <Typography>Bienvenue mon Bg {currentUser.email} {currentUser.uid}</Typography>}
      <TextField placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
      <TextField placeholder="password" type="password" onChange={(e) => setPassword(e.target.value)} />

      <Button onClick={handleCreationWithEmailClick}> Créer Son Compte</Button>
      <Button onClick={handleLoginClick}> Se Connecter</Button>
      <Button onClick={signInWithGoogle}> Sign In With Google</Button>
      <Button onClick={handleLogoutClick}> Logout</Button>

      {/* <Button onClick={signIn}> Sign In With Google</Button>

        <Button onClick={signOutWithPassword}> Sign OUt With Password</Button>
        <Button onClick={signInWithPassword}> Sign In With Password</Button>

        <Button onClick={LogOut}> Logout </Button>
        {usern ? <Button onClick={() => console.log(usern.email)}> log </Button> : <Typography>pas click</Typography>}
        <Button onClick={() => console.log(auth.currentUser)}> logitus </Button> */}

      <Typography variant="h3">{usern.email}</Typography>

      {/* <Button onClick={Start}>connect</Button> */}

    </div>
  )

}