import { FormControl, InputLabel, Select, MenuItem } from "@mui/material"


export default function SelectQualityDonation({ name, value, onChange }) {
    return (
        <>
            <FormControl sx={{ m: 0, width: '100%' }}>
                <InputLabel id="select-quality-donation-label" >Qualité du donataire</InputLabel>
                <Select
                    labelId="select-quality-donation-label"
                    id="select-quality-donation"
                    name={name}
                    value={value}
                    label="Qualité du donataire"
                    autoWidth
                    onChange={onChange}
                    size="small"
                >
                    <MenuItem value={'mariage'}>
                        Conjoint
                    </MenuItem>
                    <MenuItem value={'pacs'}>
                        Partenaire de PACS
                    </MenuItem>
                    <MenuItem value={"cohabitant"}>
                        Concubin
                    </MenuItem>
                    <MenuItem value={"child"}>
                        Enfant
                    </MenuItem>
                    <MenuItem value={"parent"}>
                        Parent
                    </MenuItem>
                    <MenuItem value={"brother"}>
                        Frère/Soeur
                    </MenuItem>
                    <MenuItem value={"nephew"}>
                        Neuve/Nièce
                    </MenuItem>
                    <MenuItem value={"other"}>
                        Autre
                    </MenuItem>
                </Select>
            </FormControl>
        </>
    )
}