import { Container, Toolbar, Typography } from "@mui/material";
import AuthProvider from "../utils/firestore-database/providers/authProvider";
import RelationEntryPdfComponent from "../components/pdf/relationEntryPdfComponent";

export default function CustomerCif() {
    return (
        <AuthProvider>
            <Container>
                <Toolbar />
                <RelationEntryPdfComponent />
            </Container>
        </AuthProvider>
    )
}