import dayjs from "dayjs";



export default function AgeCalculator (day, month, year){

    const currentDay = dayjs().date();
    const currentMonth = dayjs().month();
    const currentYear = dayjs().year();

    let age = currentYear - year


    if(month === currentMonth && day > currentDay){
        age--
    } 
    

    if(month > currentMonth){
        age--
    }

    // console.log("log" + currentMonth + month)
    // console.log(age)

    return age
    

}