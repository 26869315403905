import {
  getFirestore,
  collection,
  addDoc,
  doc,
  setDoc,
  updateDoc,
  serverTimestamp,
  getDoc,
} from "firebase/firestore";
import { createContext, useState, useEffect, useContext } from "react";
import firebaseApp from "../firebaseConfig";
import { useAuth } from "./authProvider";

const DataContext = createContext("");

export default function DataProvider({ children }) {
  const [cashData, setCashData] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);
  const [financialData, setFinancialData] = useState([]);
  const [estateData, setEstateData] = useState([]);
  const [otherData, setOtherData] = useState([]);
  const [sumData, setSumData] = useState([]);
  const [incomeData, setIncomeData] = useState([]);
  const [expenseData, setExpenseData] = useState([]);
  const [personnalData, setPersonnalData] = useState([]);
  const [cashLoading, setCashLoading] = useState(true);
  const [employeeLoading, setEmployeeLoading] = useState(true);
  const [financialLoading, setFinancialLoading] = useState(true);
  const [estateLoading, setEstateLoading] = useState(true);
  const [otherLoading, setOtherLoading] = useState(true);
  const [sumLoading, setSumLoading] = useState(true);
  const [incomeLoading, setIncomeLoading] = useState(true);
  const [expenseLoading, setExpenseLoading] = useState(true);
  const [personnalDataLoading, setPersonnalLoading] = useState(true);
  // const []

  const db = getFirestore(firebaseApp);
  const { currentUser } = useAuth();

  const cashDocRef = currentUser
    ? doc(db, currentUser.email, "data", "patrimoine", "cash")
    : null;
  const employeeDocRef = currentUser
    ? doc(db, currentUser.email, "data", "patrimoine", "employee")
    : null;
  const financialDocRef = currentUser
    ? doc(db, currentUser.email, "data", "patrimoine", "financial")
    : null;
  const estateDocRef = currentUser
    ? doc(db, currentUser.email, "data", "patrimoine", "estate")
    : null;
  const otherDocRef = currentUser
    ? doc(db, currentUser.email, "data", "patrimoine", "other")
    : null;
  const sumDocRef = currentUser
    ? doc(db, currentUser.email, "data", "patrimoine", "sum")
    : null;
  const incomeDocRef = currentUser
    ? doc(db, currentUser.email, "data", "income", "income")
    : null;
  const expenseDocRef = currentUser
    ? doc(db, currentUser.email, "data", "expense", "expense")
    : null;
  const personnalDocRef = currentUser
    ? doc(db, currentUser.email, "data", "personnal", "perso")
    : null;

  async function cashRef() {
    try {
      const docSnap = await getDoc(cashDocRef);
      if (docSnap.exists()) {
        setCashData(docSnap.data().cash);
        setCashLoading(false);
        // setNumberOfAsset(docSnap.data().numberOfCash)
        console.log("Cash Dl");
      } else {
        setCashData([{}]);
        setCashLoading(false);
        // setError('No such document!');
        // console.log("No such document!");
      }
    } catch (err) {
      //   setError(err.message);
    } finally {
      setCashLoading(false);
    }
  }

  async function estateRef() {
    try {
      const docSnap = await getDoc(estateDocRef);
      if (docSnap.exists()) {
        setEstateData(docSnap.data().estate);
        setEstateLoading(false);
        // setNumberOfAsset(docSnap.data().numberOfCash)
        console.log("Estate Dl");
      } else {
        setEstateData([{}]);
        setEstateLoading(false);
        // setError('No such document!');
        // console.log("No such document!");
      }
    } catch (err) {
      //   setError(err.message);
    } finally {
      setEstateLoading(false);
    }
  }

  async function employeeRef() {
    try {
      const docSnap = await getDoc(employeeDocRef);
      if (docSnap.exists()) {
        setEmployeeData(docSnap.data().employee);
        setEmployeeLoading(false);
        // setNumberOfAsset(docSnap.data().numberOfCash)
        console.log("Employee Dl");
      } else {
        setEmployeeData([{}]);
        setEmployeeLoading(false);
        // setError('No such document!');
        // console.log("No such document!");
      }
    } catch (err) {
      //   setError(err.message);
    } finally {
      setEmployeeLoading(false);
    }
  }

  async function financialRef() {
    try {
      const docSnap = await getDoc(financialDocRef);
      if (docSnap.exists()) {
        setFinancialData(docSnap.data().financial);
        setFinancialLoading(false);
        // setNumberOfAsset(docSnap.data().numberOfCash)
        console.log("Financial Dl");
      } else {
        setFinancialData([{}]);
        setFinancialLoading(false);
        // setError('No such document!');
        // console.log("No such document!");
      }
    } catch (err) {
      //   setError(err.message);
    } finally {
      setFinancialLoading(false);
    }
  }
  async function otherRef() {
    try {
      const docSnap = await getDoc(otherDocRef);
      if (docSnap.exists()) {
        setOtherData(docSnap.data().other);
        setOtherLoading(false);
        // setNumberOfAsset(docSnap.data().numberOfCash)
        console.log("other dl");
      } else {
        setOtherData([{}]);
        setOtherLoading(false);
        // setError('No such document!');
        // console.log("No such document!");
      }
    } catch (err) {
      //   setError(err.message);
    } finally {
      setOtherLoading(false);
    }
  }
  async function sumRef() {
    try {
      const docSnap = await getDoc(sumDocRef);
      if (docSnap.exists()) {
        setSumData(docSnap.data());
        setSumLoading(false);
        // setNumberOfAsset(docSnap.data().numberOfCash)
        console.log("Sum dl");
      } else {
        setSumData([]);
        setSumLoading(false);
        // setError('No such document!');
        // console.log("No such document!");
      }
    } catch (err) {
      //   setError(err.message);
    } finally {
      setSumLoading(false);
    }
  }
  async function incomeRef() {
    try {
      const docSnap = await getDoc(incomeDocRef);
      if (docSnap.exists()) {
        setIncomeData(docSnap.data().income);
        setIncomeLoading(false);
        // setNumberOfAsset(docSnap.data().numberOfCash)
        console.log("income dl");
      } else {
        setIncomeData([{}]);
        setIncomeLoading(false);
        // setError('No such document!');
        // console.log("No such document!");
      }
    } catch (err) {
      //   setError(err.message);
    } finally {
      setIncomeLoading(false);
    }
  }
  async function expenseRef() {
    try {
      const docSnap = await getDoc(expenseDocRef);
      if (docSnap.exists()) {
        setExpenseData(docSnap.data().expense);
        setExpenseLoading(false);
        // setNumberOfAsset(docSnap.data().numberOfCash)
        console.log("expense dl");
      } else {
        setExpenseData([{}]);
        setExpenseLoading(false);
        // setError('No such document!');
        // console.log("No such document!");
      }
    } catch (err) {
      //   setError(err.message);
    } finally {
      setExpenseLoading(false);
    }
  }
  async function personnalRef() {
    try {
      const docSnap = await getDoc(personnalDocRef);
      if (docSnap.exists()) {
        setPersonnalData(docSnap.data().perso);
        setPersonnalLoading(false);
        // setNumberOfAsset(docSnap.data().numberOfCash)
        console.log("personnal dl");
      } else {
        setPersonnalData([]);
        setPersonnalLoading(false);
        // setError('No such document!');
        // console.log("No such document!");
      }
    } catch (err) {
      //   setError(err.message);
    } finally {
      setPersonnalLoading(false);
    }
  }

  // Bon là on appelle direct au chargement de currentUser, à voir si c'est le plus opti
  useEffect(() => {
    cashRef(),
      financialRef(),
      sumRef(),
      employeeRef(),
      estateRef(),
      otherRef(),
      incomeRef(),
      expenseRef(),
      personnalRef();
    // setCashLoading(false)
  }, [currentUser]);

  async function putDataInFirebase(data, docName, collectionName, dataName) {
    const dataRef = doc(
      db,
      currentUser.email,
      docName,
      collectionName,
      dataName
    );

    // setDoc = on fait nous même le nom du doc vis addDOc = le nom du doc est généré par firebase (c'est pour ça qu'il prend une collection et pas un doc pour dataRef)
    await setDoc(dataRef, { [dataName]: data });
    // setCashLoading(true)

    // Update the timestamp field with the value from the server
    //       const updateTimestamp = await updateDoc(docRef, {
    //         timestamp: serverTimestamp()
    //     });
    //   }

    //On appelle la ref pour que ça refresh les data changés
    switch (dataName) {
      case "cash":
        await cashRef();
        break;
      case "employee":
        await employeeRef();
        break;
      case "estate":
        await estateRef();
        break;
      case "other":
        await otherRef();
        break;
      case "financial":
        await financialRef();
        break;
      case "income":
        await incomeRef();
        break;
      case "expense":
        await expenseRef();
        break;
      case "perso":
        await personnalRef();
        break;
      default:
        console.log("no case");
    }
    console.log("c'est fait " + dataName);
  }

  async function putSumInFirebase(
    sum,
    docName,
    collectionName,
    sumDoc,
    sumName
  ) {
    const docRef = doc(db, currentUser.email, docName, collectionName, sumDoc);

    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      await updateDoc(docRef, { [sumName]: sum });
      console.log("update");
    } else {
      await setDoc(docRef, { [sumName]: sum });
      console.log("creation");
    }

    await sumRef();

    console.log("weh");
  }

  const value = {
    cashData,
    cashLoading,
    employeeData,
    employeeLoading,
    financialData,
    financialLoading,
    sumData,
    sumLoading,
    estateData,
    estateLoading,
    otherData,
    otherLoading,
    incomeData,
    incomeLoading,
    expenseData,
    expenseLoading,
    personnalData,
    personnalDataLoading,
    putDataInFirebase,
    putSumInFirebase,
  };

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
}

export const useData = () => {
  return useContext(DataContext);
};
