import { Grid, Paper, Button } from "@mui/material";

export default function AddPropertiesWrapper({ children, onClick, md = 4 }) {
  return (
    <Grid item xs={12} md={md}>
      <Paper elevation={2} sx={{ display: "flex" }}>
        <Grid container spacing={1} sx={{ mt: 2, pl: 3, pr: 3, pt: 3 }}>
          {children}
          <Grid item xs={12} container justifyContent="flex-end">
            <Button onClick={onClick}>Effacer</Button>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}
