import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

export default function SelectExpenseType({ data, setData }) {
  return (
    <>
      <FormControl sx={{ m: 0, width: "100%" }}>
        <InputLabel id="select-income-label">Type</InputLabel>
        <Select
          labelId="select-income-label"
          id="select-income"
          name="type"
          value={data.type || ""}
          label="Type"
          autoWidth
          onChange={setData}
        >
          {/* <MenuItem value={"Traitement et Salaire"}>
            Traitement et Salaire
          </MenuItem> */}
          <MenuItem value={"Loyers"}>Loyer</MenuItem>
          <MenuItem value={"Crédit"}>Remboursement de crédit</MenuItem>
          <MenuItem value={"Impôts"}>Impôts</MenuItem>
          {/* <MenuItem value={"Alimentaire"}>Alimentaire</MenuItem>
          <MenuItem value={"Loisir"}>Loisir</MenuItem> */}
          <MenuItem value={"Autre"}>Autre</MenuItem>
        </Select>
      </FormControl>
    </>
  );
}
