import { Fragment, useState } from "react";
import {
  Button,
  TextField,
  Grid,
  Toolbar,
  CircularProgress,
  Paper,
} from "@mui/material";
import { useEffect } from "react";
import SelectAddEmployee from "../../../components/select/addProperty/selectAddEmployee";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import SumPropertyCalcul from "../../../utils/calculator/sumPropertyCalcul";
import AgeCalculator from "../../../utils/calculator/ageCalulator";
import onlyNumberKeyDown from "../../../utils/handleKeyDown/onlyNumberKeyDown";
import { useData } from "../../../utils/firestore-database/providers/dataProvider";
import AddPropertiesWrapper from "../../../components/wraper/addPropertiesWrapper";
import AddPropertiesButtons from "../../../components/button/addPropertiesButtons";
import { useNavigate } from "react-router-dom";
export default function AddEmployee() {
  const { employeeData, putDataInFirebase, employeeLoading, putSumInFirebase } =
    useData();

  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setData(employeeData);
    setLoading(employeeLoading);
  }, [employeeData, employeeLoading]);

  const handleClick = () => {
    setData([...data, {}]);
  };

  const handleChange = (e, i) => {
    const { name, value } = e.target;

    const onchangeVal = [...data];

    name === "amount"
      ? (onchangeVal[i][name] = parseInt(value))
      : (onchangeVal[i][name] = value);

    setData(onchangeVal);
  };

  const handleDelete = (i) => {
    const deleteVal = [...data];
    deleteVal.splice(i, 1);
    setData(deleteVal);
  };

  function handleDateChange(e, index) {
    const date = dayjs(e);

    const year = date.year();
    const month = date.month();
    const day = date.date();

    const dateString = e.toString();
    const age = AgeCalculator(day, month, year);

    const onchangeVal = [...data];
    onchangeVal[index]["date"] = dateString;

    const onchangeVal2 = [...data];
    onchangeVal2[index]["age"] = age;
    setData(onchangeVal, onchangeVal2);
  }

  function handleSubmit(event) {
    event.preventDefault();

    const sum = SumPropertyCalcul(data, "Placement salariale");

    putSumInFirebase(sum, "data", "patrimoine", "sum", "employee");
    putDataInFirebase(data, "data", "patrimoine", "employee");
    navigate("/patrimoine");
  }

  return (
    <>
      <Toolbar />
      {loading ? (
        <>
          <CircularProgress />
        </>
      ) : (
        <>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              {data.map((item, index) => (
                <Fragment key={`fragment ${index}`}>
                  <AddPropertiesWrapper onClick={() => handleDelete(index)}>
                    <Grid item xs={12} md={6}>
                      <SelectAddEmployee
                        data={item}
                        setData={(e) => handleChange(e, index)}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        required
                        name="amount"
                        type="number"
                        value={item.amount || ""}
                        onKeyDown={onlyNumberKeyDown}
                        label="Solde"
                        variant="outlined"
                        onChange={(e) => handleChange(e, index)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name="bank"
                        value={item.bank || ""}
                        label="Organisme"
                        variant="outlined"
                        onChange={(e) => handleChange(e, index)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale={"fr"}
                      >
                        <DatePicker
                          label="Date d'ouverture'"
                          value={dayjs(item.date)}
                          onChange={(e) => handleDateChange(e, index)}
                        />
                      </LocalizationProvider>
                    </Grid>
                  </AddPropertiesWrapper>
                </Fragment>
              ))}
              <AddPropertiesButtons onClick={handleClick} />
            </Grid>
          </form>
        </>
      )}
    </>
  );
}
