import { CardContent, Typography, Card } from "@mui/material";

export default function SumCard({ sum, title }) {
  return (
    <Card>
      <CardContent>
        <Typography component="h2" variant="h6" color="primary" gutterBottom>
          {title}
        </Typography>
        <Typography component="p" variant="h4">
          {`${sum.toLocaleString()} €`}
        </Typography>
        <Typography color="text.secondary" sx={{ flex: 1 }}>
          A ce jour
        </Typography>
      </CardContent>
    </Card>
  );
}
