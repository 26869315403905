import { FormControl, InputLabel, Select, MenuItem } from "@mui/material"

export default function SelectAddCash({ data, setData }) {
    return (
        <>
            <FormControl sx={{ m: 0, width: '100%' }}>
                <InputLabel id="select-cash-label">Type</InputLabel>
                <Select
                    labelId="select-cash-label"
                    id="select-cash"
                    name="type"
                    value={data.type || ''}
                    label="Type"
                    autoWidth
                    onChange={setData}>
                    <MenuItem value={'Compte Carte'}>
                        Compte Carte
                    </MenuItem>
                    <MenuItem value={'Livret A'}>
                        Livret A
                    </MenuItem>
                    <MenuItem value={"LDD"}>
                        LDD
                    </MenuItem>
                    <MenuItem value={"CEL"}>
                        Compte épargne logement
                    </MenuItem>
                    <MenuItem value={"LEP"}>
                        LEP
                    </MenuItem>
                </Select>
            </FormControl>
        </>
    )
}