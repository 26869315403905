import {
  Checkbox,
  Button,
  Toolbar,
  Typography,
  CircularProgress,
  Box,
  Grid,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import { useState } from "react";
import { getFirestore } from "firebase/firestore";
import { doc, getDoc, getDocs } from "firebase/firestore";
import { useAuth } from "../../utils/firestore-database/providers/authProvider";
import firebaseApp from "../../utils/firestore-database/firebaseConfig";
import { useEffect } from "react";
import YourConselor from "../card/relationEntryCards/yourConselor";
import CardEntreprise from "../card/relationEntryCards/cardEntreprise";
import SignaturePdf from "./signaturePdf";
import jsPDF from "jspdf";
import { ploup } from "../strings/relationEntryStrings";
import RelationEntryPdfCreation from "./relationEntryPdfCreation";

export default function RelationEntryPdfComponent() {
  const db = getFirestore(firebaseApp);
  const { currentUser, loading: authLoading } = useAuth();
  const docRef = currentUser
    ? doc(db, currentUser.email, "data", "infos", "perso")
    : null;

  async function ref() {
    // if (!docRef) {
    //     console.log("No user is logged in, or invalid document reference.");
    //     setData({});
    //     setLoading(false);
    //     return;
    // }

    if (authLoading) {
      // Attendre que l'authentification soit chargée
      return;
    }

    try {
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setData(docSnap.data());
        // setNumberOfAsset(docSnap.data().numberOfCash)
        setLoading(false);
        console.log("Document data:", docSnap.data());
      } else {
        setData({});
        // setError('No such document!');
        console.log("No such document!");
        setLoading(false);
      }
    } catch (err) {
      console.log(err.message);
    }
  }

  useEffect(() => {
    ref();
  }, [currentUser]);

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [signatureImageUrl, setSignatureImageUrl] = useState(null);
  const [isSigned, setIsSigned] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  return (
    <>
      {loading ? (
        <>
          <CircularProgress />
        </>
      ) : (
        <>
          {!isSigned ? (
            <>
              <Typography variant="h3">
                {" "}
                Entrée en relation {data.name}
              </Typography>

              <Typography variant="body2">
                {" "}
                Vous avez choisi ou êtes sur le point de confier la mission de
                vous assister, à un professionnel réglementé et contrôlé, vous
                devez donc garder en mémoire les éléments suivants :
              </Typography>

              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <CardEntreprise />
                </Grid>
                <Grid item xs={6}>
                  <YourConselor />
                </Grid>
              </Grid>

              <Box sx={{ bgcolor: "#e2efd9" }}>
                <Typography color={"black"}>
                  Les informations recueillies sur ce formulaire sont
                  enregistrées dans un fichier informatisé par Isonos pour le
                  bon déroulement de notre mission de CGP Elles sont conservées
                  pendant 10 ans et sont destinées à Isonos Conformément à la
                  loi « informatique et libertés », vous pouvez exercer votre
                  droit d’accès, d’opposition, d’effacement, de limitation du
                  traitement, de portabilité des données vous concernant et les
                  faire rectifier en contactant : Isonos a contact@isonos.fr
                  Vous pouvez également introduire une réclamation au sujet du
                  traitement de vos données auprès de la CNIL.
                </Typography>
                <Typography>{ploup}</Typography>
              </Box>
              <Typography paragraph></Typography>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isChecked}
                      onChange={(e) => setIsChecked(!isChecked)}
                    />
                  }
                  label="J'ai bien pris en compte toutes les infos de ce PGM dde CGP"
                />
              </FormGroup>
              {isChecked && (
                <SignaturePdf
                  setSignatureImageUrl={setSignatureImageUrl}
                  setIsSigned={setIsSigned}
                />
              )}
            </>
          ) : (
            <RelationEntryPdfCreation
              signatureImageUrl={signatureImageUrl}
              setIsSigned={setIsSigned}
            />
          )}
        </>
      )}
    </>
  );
}
