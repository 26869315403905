import { useData } from "../../../utils/firestore-database/providers/dataProvider";
import IncomeChart from "../../charts/charts/incomeChart";
import IncomeTable from "../../table/income-table/incomeTable";
import { Stack, Divider, Grid, Paper, Button } from "@mui/material";
import SumCard from "../../card/sumCard/sumCard";
import { Link } from "react-router-dom";

export default function IncomeDisplay() {
  const { incomeData } = useData();

  const initialValue = 0;

  const sum = incomeData.reduce(
    (accumulator, currentvalue) => accumulator + currentvalue.amount,
    initialValue
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={8} lg={6}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: 300,
          }}
        >
          <IncomeChart data={incomeData} />
        </Paper>
      </Grid>
      <Grid item xs={12} md={4} lg={3}>
        <Stack
          // alignItems="center"
          // //   justifyContent="center"
          // divider={<Divider orientation="vertical" flexItem />}
          direction="column"
          spacing={2}
        >
          {sum > 0 ? (
            <SumCard sum={sum} title="Votre revenu annuel" />
          ) : (
            <SumCard sum={0} title="Votre revenu annuel" />
          )}
          <Button
            sx={{ padding: "55px 20px" }}
            variant="contained"
            component={Link}
            to={"../addIncome"}
          >
            Ajouter
          </Button>
        </Stack>
        {/* </Paper> */}
      </Grid>
      <Grid item xs={12}>
        <IncomeTable data={incomeData} sum={sum} />
      </Grid>
    </Grid>
  );
}
