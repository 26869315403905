import { Typography } from "@mui/material";
import CashTable from "../../table/property-table/cashTable";
import { useData } from "../../../utils/firestore-database/providers/dataProvider";
import PropertiesTable from "../../table/property-table/propertiesTable";
import EstateTable from "../../table/property-table/estateTable";

export default function DetailPatrimoineDisplay() {
  const { employeeData, financialData, estateData, otherData, cashData } =
    useData();
  return (
    <>
      <PropertiesTable
        data={cashData}
        title="Liquidités"
        link="/addGlobalProperty/addCash"
      />
      <PropertiesTable
        data={financialData}
        title="Placement Financier"
        link="/addGlobalProperty/addFinancial"
      />
      <EstateTable
        data={estateData}
        title="Immobilier"
        link="/addGlobalProperty/addEstate"
      />
      <PropertiesTable
        data={employeeData}
        title="Placement Salarial"
        link="/addGlobalProperty/addEmployee"
      />
      <PropertiesTable
        data={otherData}
        title="Autre"
        link="addGlobalProperty/addOther"
      />
    </>
  );
}
