import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

export default function SelectInvestTypeEstate({ data, setData }) {
  return (
    <>
      <FormControl sx={{ m: 0, width: "100%" }}>
        <InputLabel id="select-invest-type-estate-label">
          Mode d'investissement
        </InputLabel>
        <Select
          labelId="select-invest-type-estate-label"
          id="select-invest-type-estate"
          name="investType"
          value={data.investType || ""}
          label="Mode d'investissement"
          autoWidth
          onChange={setData}
        >
          <MenuItem value={"SCPI"}>SCPI</MenuItem>
          <MenuItem value={"OPCI"}>OPCI</MenuItem>
          <MenuItem value={"crowdfunding"}>Crowdfunding</MenuItem>
          <MenuItem value={"Autre"}>Autre</MenuItem>
        </Select>
      </FormControl>
    </>
  );
}
