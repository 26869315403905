import { Box, Tabs, Toolbar, Typography } from "@mui/material";
import PersonnalInformationDisplay from "../../components/display/personnal-information-display/personnalInformationDisplay";

//Y avait un probleme avec le wrap du children qui était typographie

export default function PersonnalInformation() {
  return (
    <>
      <Typography variant="h4">INFORMATIONS PERSONNELLES</Typography>
      <Toolbar />
      <PersonnalInformationDisplay />
    </>
  );
}
