import {
  Button,
  CardActionArea,
  CardContent,
  Typography,
  Card,
  Toolbar,
} from "@mui/material";
import { Link, Outlet } from "react-router-dom";
import AddPropertyCard from "../../components/card/addPropertyCard";

export default function AddGlobalProperty() {
  return (
    <>
      <Toolbar />
      <Outlet />
      <AddPropertyCard />
    </>
  );
}
