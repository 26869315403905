import {
  TableContainer,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  Paper,
  Typography,
} from "@mui/material";

import { blue } from "@mui/material/colors";

export default function SumTable({ data, sum }) {
  // const dataArray = Object.values(data);
  // const initialValue = 0;
  // const sum = dataArray.reduce(
  //   (accumulator, currentvalue) => accumulator + currentvalue.amount,
  //   initialValue
  // );

  return (
    <>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="tableau patrimoine total">
          <TableHead>
            <TableRow
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                bgcolor: blue[100],
              }}
            >
              <TableCell>Type</TableCell>

              <TableCell align="right">Montant</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow
                key={`row-${index}`}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.type}
                </TableCell>

                <TableCell align="right">
                  {`${row.amount.toLocaleString()} €`}
                </TableCell>
              </TableRow>
            ))}
            <TableRow
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                bgcolor: blue[300],
              }}
            >
              <TableCell component="th" scope="row">
                Total
              </TableCell>

              <TableCell align="right">{`${sum.toLocaleString()} €`}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
