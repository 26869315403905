import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import GoogleButton from "../../components/button/googleButton";
import LinkedInButton from "../../components/button/linkedInButton";
import { useAuth } from "../../utils/firestore-database/providers/authProvider";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";

export default function SignInPage() {
  const {
    currentUser,
    loading,
    LoginWithEmail,
    signInWithGoogle,
    signInErrorCode,
    setSignInErrorCode,
  } = useAuth();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser) {
      navigate("/");
    }
  }, [currentUser, navigate]);

  useEffect(() => {
    if (signInErrorCode) {
      setSignInErrorCode("");
    }
  }, []);

  function handleSubmit(e) {
    e.preventDefault();
    LoginWithEmail(email, password);
    // setSignInErrorCode("");
  }

  function errorMessageDisplay(errorCode) {
    switch (errorCode) {
      case "auth/invalid-email":
        return "Adresse mail manquante";
      case "auth/missing-password":
        return "Mot de passe manquant";
      case "auth/invalid-credential":
        return "Mail ou mot de passe erroné";
      default:
        return "Un problème est survenu ";
    }
  }

  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : (
        <Grid container component="main" sx={{ height: "100vh" }}>
          <Grid
            item
            xs={false}
            sm={4}
            md={7}
            sx={{
              // backgroundImage: 'url("/images/sign-in-side-bg.png")',
              // backgroundColor:
              //   "linear-gradient(27deg, rgba(9,9,121,1) 0%, rgba(0,212,255,1) 53%, rgba(255,255,255,1) 87%)",
              backgroundImage:
                // "linear-gradient(27deg, rgba(66,133,240,1) 39%, rgba(255,255,255,1) 75%)",
                "linear-gradient(270deg, rgba(66,133,240,1) 39%, rgba(255,255,255,1) 75%)",
              backgroundSize: "cover",
              backgroundPosition: "left",
            }}
          />
          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={6}
            square
          >
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Connexion
              </Typography>

              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <GoogleButton onClick={signInWithGoogle} />
                </Grid>

                <Grid item xs={12}>
                  <Box
                    component="form"
                    noValidate
                    onSubmit={handleSubmit}
                    sx={{ mt: 1 }}
                  >
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label="Adresse Email"
                      name="email"
                      autoComplete="email"
                      autoFocus
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      name="password"
                      label="Mot De Passe"
                      type="password"
                      id="password"
                      autoComplete="current-password"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    {signInErrorCode && (
                      <Typography
                        variant="h6"
                        sx={{
                          color: "error.main",
                        }}
                      >
                        {errorMessageDisplay(signInErrorCode)}
                      </Typography>
                    )}
                    {/* <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label="Remember me"
                /> */}
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                    >
                      Se connecter
                    </Button>
                    <Grid container>
                      <Grid item xs>
                        {/* <Link href="#" variant="body2">
                      Mot de passe oublié ?
                    </Link> */}
                      </Grid>
                      <Grid item>
                        <Link to="/signUp">
                          {"Pas encore de compte ? Créez un compte"}
                        </Link>
                      </Grid>
                    </Grid>


                  </Box>
                </Grid>
              </Grid>
            </Box>

          </Grid>
        </Grid>
      )}
    </>
  );
}
