

import firebase from 'firebase/compat/app';

import 'firebase/auth';

import 'firebase/firestore';

import { initializeApp } from "firebase/app";


// Import other Firebase services as needed

const firebaseConfig = {
    apiKey: "AIzaSyBJSBOYf1YQBo8Z0NWQLEQnkmVR91s-nCo",
    authDomain: "arthur-le-crack.firebaseapp.com",
    projectId: "arthur-le-crack",
    storageBucket: "arthur-le-crack.appspot.com",
    messagingSenderId: "973819242464",
    appId: "1:973819242464:web:0827aae2e43600f9f36d85",
    measurementId: "G-2D30M86726"

};

//il parait que ça c'est mieux mais je comprend pas comment ça marche
// firebase.initializeApp(firebaseConfig);

// export default firebase;

const firebaseApp = initializeApp(firebaseConfig);

export default firebaseApp;

