
//Fonction pour n'avoir que des chiffres
export default function onlyNumberKeyDown(event) {
    // Permet les touches de contrôle comme backspace, delete, etc.
    if (
        event.key !== 'Backspace' &&
        event.key !== 'Delete' &&
        event.key !== 'ArrowLeft' &&
        event.key !== 'ArrowRight' &&
        // ça ça veut dire si c'est pas un nombre avec le regex \d qui veut dire chiffre (c'est comme [0-9]) et ^ qui veut dire doit commencer et $ doit finir et ensuite la méthode test pour voir si ça match avec le key voilà lol
        !/^\d$/.test(event.key)
    ) {
        event.preventDefault();
    }
}