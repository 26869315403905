import { Button } from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google";

export default function GoogleButton({ onClick }) {
  return (
    <Button
      fullWidth
      variant="contained"
      color="primary"
      startIcon={<GoogleIcon />}
      onClick={onClick}
      sx={{
        backgroundColor: "#4285F4",
        "&:hover": {
          backgroundColor: "#357ae8",
        },
        textTransform: "none",
        fontWeight: "bold",
        mt: 1,
      }}
    >
      Se connecter avec Google
    </Button>
  );
}
