import { createTheme } from "@mui/material/styles";

export const Theme = createTheme({
  palette: {
    mode: "light",
    //   primary: {
    //     main: "#ab47bc",
    //     dark: "#7e2c7d",
    //   },
    //   secondary: {
    //     main: "#7e57c2",
    //   },
    //   info: {
    //     main: "#f6299f",
    //     light: "#53a7f7",
    //   },
    //   background: {
    //     //couleur stylé : #000126
    //     // default: '#000000',
    //     paper: "#000126",
    //   },
    // },
    // // spacing: 8,
    // // boxShadow:'0 0 8px 5px rgba(195,1,171,0.2)',
    // shape: {
    //   borderRadius: 10,
    // },
    // body: {
    //   // border: '2px solid blue',
    //   backgroundImage:
    //     "linear-gradient(0deg, rgba(0,1,38,1) 58%, rgba(210,29,209,1) 100%)",
  },

  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h2: "h2",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          //background:'linear-gradient(264deg, rgba(34,193,195,0.5) 0%, rgba(253,187,45,0.5) 100%)',
          //Celui là c'est Colb mdr
          // background:
          //   "linear-gradient(90deg, rgba(21,20,60,0.75) 24%, rgba(87,42,117,0.5) 69%, rgba(210,29,29,0.5) 100%)",
          //background:'linear-gradient(90deg, rgba(123,63,251,1) 0%, rgba(252,70,107,1) 100%);',
          // 'transparent',
          // borderColor:'linear-gradient(60deg, #00f, #00e8ff)',
          // boxSizing: 'border-box',
          // borderColor: 'green',
          // border: 'solid  #00e8ff 4px',
          // boxShadow: '0 0 20px #eee',
          // color: "white",
          // height: 48,
          // padding: "0 30px",
          // transition: 4,
          // borderRadius:60,
          // '&:hover': {
          //   backgroundPosition: 'right',
          //   background: '#fff',
          // },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          // boxShadow: "0 0 3px 1px rgba(195,1,171,0.5)",
          borderRadius: "10px",
          //alors le borderColor c'est pour le MuiFormControl je sais pas c quoi mdr
          // borderColor: "red",
          width: "100%",

          // boxSizing:'border-box'
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          // boxShadow: '0 0 8px 5px rgba(195,1,171,0.5)',
          // borderRadius: '10px',
          //alors le borderColor c'est pour le MuiFormControl je sais pas c quoi mdr
          // borderColor: 'red',
        },
      },
    },
    MuiInputformControl: {
      styleOverrides: {
        root: {
          // boxShadow: '0 0 8px 5px rgba(195,1,171,0.5)',
          // borderRadius: '10px',
          //alors le borderColor c'est pour le MuiFormControl je sais pas c quoi mdr
          // borderColor: 'red',
        },
      },
    },
  },
});
