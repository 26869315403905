import { createContext, useState, useEffect, useContext } from "react";
import App from "../../../pages/app";
import firebaseApp from "../firebaseConfig";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
  onAuthStateChanged,
  signInWithRedirect,
} from "firebase/auth";

const AuthContext = createContext("");

// const auth = firebaseApp.auth()
//ça marche pas lol en fait c'est l'ancienne version donc balec mdr vla les IA du cul

const auth = getAuth(firebaseApp);

//Pour passer le Auth à travers l'appli
export default function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState("");
  const [signInErrorCode, setSignInErrorCode] = useState("");
  const [signUpErrorCode, setSignUpErrorCode] = useState("");

  //ça c'est Gemini qui me dit de la faire, je le laisse au cas ou ça doit servir à quelque chose
  const [loading, setLoading] = useState(true);

  const provider = new GoogleAuthProvider();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setLoading(false);
      // console.log('logee')
    });

    return unsubscribe;
  }, []);

  //   function handleLogin(email, password){
  //     signInWithEmailAndPassword(auth, email, password)
  //     .then((userCredential) => {
  //       const user = userCredential.user;
  //     })
  //     .catch((error) => {
  //       const errorCode = error.code;

  //       const errorMessage = error.message;

  //       console.log(errorCode, errorMessage)
  //     })
  //   };

  async function CreationWithEmail(email, password) {
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        setCurrentUser(user);
      })
      .catch((error) => {
        // const errorCode = error.code;

        // const errorMessage = error.message;

        // console.log(errorCode, errorMessage + "oui");
        setSignUpErrorCode(error.code);
      });
  }

  async function LoginWithEmail(email, password) {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        setCurrentUser(user);
      })
      .catch((error) => {
        // const errorCode = error.code;
        setSignInErrorCode(error.code);

        // const errorMessage = error.message;

        // console.log(errorCode, errorMessage);
      });
  }

  async function signInWithGoogle() {
    try {
      await signInWithPopup(auth, provider);
    } catch (err) {
      console.error(err);
    }
  }

  async function Logout() {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
      })
      .catch((error) => {
        // An error happened.
      });
  }

  const value = {
    currentUser,
    loading,
    signInErrorCode,
    signUpErrorCode,
    setSignInErrorCode,
    setSignUpErrorCode,
    CreationWithEmail,
    LoginWithEmail,
    Logout,
    signInWithGoogle,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

//C4est pour éviter d'utiliser le useContext partout je pense (c'est Gemini qui m'a dit mais ça a l'air pas mal)
export const useAuth = () => {
  return useContext(AuthContext);
};
