import {
  TextField,
  Typography,
  Grid,
  Toolbar,
  CircularProgress,
  Button,
} from "@mui/material";
import { useState, Fragment } from "react";
import SumPropertyCalcul from "../../../utils/calculator/sumPropertyCalcul";
import AgeCalculator from "../../../utils/calculator/ageCalulator";
import onlyNumberKeyDown from "../../../utils/handleKeyDown/onlyNumberKeyDown";

import SelectExpenseType from "../../select/select-expense/selectExpenseType";
import { useData } from "../../../utils/firestore-database/providers/dataProvider";
import { useEffect } from "react";
import AddPropertiesButtons from "../../button/addPropertiesButtons";
import AddPropertiesWrapper from "../../wraper/addPropertiesWrapper";
import { useNavigate } from "react-router-dom";

export default function ExpenseInput() {
  const { expenseData, putDataInFirebase, expenseLoading, putSumInFirebase } =
    useData();

  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  // On pourrait peut être aussi faire appel diretement appel à la fonction cashref dans le useData ptet, à voir si ça fait plus d'appel
  useEffect(() => {
    setData(expenseData);
    setLoading(expenseLoading);
  }, [expenseData, expenseLoading]);

  const handleClick = () => {
    setData([...data, {}]);
  };

  const handleChange = (e, i) => {
    const { name, value } = e.target;

    const onchangeVal = [...data];

    name === "amount"
      ? (onchangeVal[i][name] = parseInt(value))
      : (onchangeVal[i][name] = value);

    setData(onchangeVal);
  };

  const handleDelete = (i) => {
    const deleteVal = [...data];
    deleteVal.splice(i, 1);
    setData(deleteVal);
  };

  function handleSubmit(event) {
    event.preventDefault();

    const sum = SumPropertyCalcul(data, "income");

    // putSumInFirebase(sum, "data", "income", "sum", "income");
    putDataInFirebase(data, "data", "expense", "expense");

    navigate("/expense");
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={1}>
          {data.map((item, index) => (
            <Fragment key={`fragment ${index}`}>
              <AddPropertiesWrapper onClick={() => handleDelete(index)}>
                <Grid item xs={12}>
                  <SelectExpenseType
                    data={item}
                    setData={(e) => handleChange(e, index)}
                  />
                </Grid>

                {item.type === "Autre" &&
                  <Grid item xs={12}>
                    <TextField
                      required
                      name="otherType"
                      value={item.otherType || ""}
                      id="2"
                      label="Autre dépense"
                      variant="outlined"
                      onChange={(e) => handleChange(e, index)}
                    />
                  </Grid>
                }
                <Grid item xs={12}>
                  <TextField
                    required
                    name="amount"
                    value={item.amount || ""}
                    onKeyDown={onlyNumberKeyDown}
                    id="1"
                    label="Montant annuel"
                    variant="outlined"
                    onChange={(e) => handleChange(e, index)}
                  />
                </Grid>
              </AddPropertiesWrapper>
            </Fragment>
          ))}
          <AddPropertiesButtons onClick={handleClick} />
        </Grid>
      </form>
    </>
  );
}
