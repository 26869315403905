
import { blue } from "@mui/material/colors";
import { Box, Typography } from "@mui/material";
import { Fragment } from "react";



export default function CustomTooltip({ active, payload, label }) {

    if (active && payload?.length) {
        return (
            <div className="bg-warning p-3 shadow">
                {/* //     <span>bale</span>
            //     <br /> */}
                <Box sx={{
                    p: 1,
                    bgcolor: blue[100]
                }}>
                    {payload.map((ele, index) => (
                        <Fragment key={index}>
                            {/* <small key={index} className="text-secondary">
                                {ele.name} : {ele.value}
                            </small>
                            <br /> */}
                            {/* <Typography>
                                label
                            </Typography> */}
                            <Typography>
                                {ele.name} : {ele.value}
                            </Typography>

                        </Fragment>
                    ))}
                </Box>
            </div >
        );
    }

    return null;
};