import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

import {
  createBrowserRouter,
  Routes,
  Route,
  Router,
  RouterProvider,
  createRoutesFromElements,
} from "react-router-dom";
import Home from "./pages/home";
import ErrorPage from "./pages/error-page";
import Succession from "./pages/succession";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import App from "./pages/app";
import Patrimoine from "./pages/patrimoine";
import { Theme } from "./utils/theme";
import AddGlobalProperty from "./pages/addProperty/addGlobalProperty";
import AddCash from "./pages/addProperty/add-properties/addCash";
import AddEmployee from "./pages/addProperty/add-properties/addEmployee";
import AddEstate from "./pages/addProperty/add-properties/addEstate";
import AddFinancial from "./pages/addProperty/add-properties/addFinancial";
import AddOther from "./pages/addProperty/add-properties/addOther";
import Connexion from "./pages/connexion";
import AuthProvider, {
  useAuth,
} from "./utils/firestore-database/providers/authProvider";
import CustomerCif from "./pages/customerCif";
import DataProvider from "./utils/firestore-database/providers/dataProvider";
import AddIncome from "./pages/income/add-income/addIncome";
import Income from "./pages/income/income";
import Expense from "./pages/expense/expense";
import AddExpense from "./pages/expense/add-expense/addExpense";
import SignInPage from "./pages/connexion/signInPage";
import SignUpPage from "./pages/connexion/signUpPage";
import PersonnalInformation from "./pages/personnal-information/personnalInformation";

const retourSecondd = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<Home />} />,
      <Route path="/" element={<App />}>
        <Route path="connexion" element={<Connexion />} />, ,
        <Route path="succession" element={<Succession />} />,
        <Route path="infospersos" element={<PersonnalInformation />} />
        <Route path="addGlobalProperty" element={<AddGlobalProperty />}></Route>
        <Route path="addGlobalProperty/addCash" element={<AddCash />} />
        <Route path="addGlobalProperty/addEstate" element={<AddEstate />} />
        <Route
          path="addGlobalProperty/addFinancial"
          element={<AddFinancial />}
        />
        <Route path="addGlobalProperty/addEmployee" element={<AddEmployee />} />
        <Route path="addGlobalProperty/addOther" element={<AddOther />} />
        <Route path="patrimoine" element={<Patrimoine />} />
        <Route path="addIncome" element={<AddIncome />} />
        <Route path="income" element={<Income />} />
        <Route path="expense" element={<Expense />} />
        <Route path="addExpense" element={<AddExpense />} />
      </Route>
      <Route path="signIn" element={<SignInPage />} />
      <Route path="signUp" element={<SignUpPage />} />,
      {/* <Route path='connexion' element ={<Connexion />} />, */}
      <Route path="test" element={<App />} />
      <Route path="customerCifDocument" element={<CustomerCif />} />
      <Route path="*" element={<ErrorPage />} />
    </>
  )
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <div translate="no">
      <ThemeProvider theme={Theme}>
        <CssBaseline />
        <AuthProvider>
          <DataProvider>
            <RouterProvider router={retourSecondd} />
            {/* <RouterProvider router={router} /> */}
            {/* <App /> */}
          </DataProvider>
        </AuthProvider>
      </ThemeProvider>
    </div>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
