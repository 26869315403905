import { CardContent, Typography, Card, Button, CardActions, Collapse, Box } from "@mui/material";
import { Share } from "@mui/icons-material";
import { ExpandMore } from "@mui/icons-material";
import { useState } from "react";


export default function () {
    const [expanded, setExpanded] = useState(false)
    return (
        <>
            <Card>
                <CardContent>
                    <Typography variant="h4"> Votre conseiller </Typography>
                    <Box border={"solid 2px"}>
                        <Typography paragraph>
                            Votre conseiller (ou intermédiaire) est immatriculé au Registre Unique des Intermédiaires en Assurance, Banque et Finance (ORIAS)
                            sous le n° d’immatriculation : 24002092
                        </Typography>
                    </Box>

                </CardContent>
                <CardActions>
                    <ExpandMore expand={expanded} onClick={() => { setExpanded(!expanded) }} aria-expanded={expanded}
                        aria-label="show more" />
                </CardActions>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <CardContent>
                        <Typography
                            paragraph>
                            (Vous pouvez vérifier cette immatriculation sur le site internet ORIAS <a href="https://www.orias.fr/">orias.fr</a>) au titre des activités réglementées suivantes :<br /><br />
                            <u>CIF (Conseiller en Investissements Financiers) susceptible de fournir des conseils en investissement de manière indépendante</u> ;<br /><br />
                            enregistré auprès de l’Association Nationale des Conseils Financiers-CIF (ANACOFI-CIF), association agréée par l’Autorité des Marchés Financiers (AMF),<br /><br />
                            Adresse courrier : 17 Place de la Bourse 75082 Paris cedex 02<br /><br />
                            Adresse internet : <a href="https://www.amf-france.org">amf-france.org</a>;<br /><br />
                            Cette activité est contrôlable par l’AMF.<br /><br />
                            <u>IAS (Intermédiaire en Assurance)</u> : <br />catégorie courtier et type C d’intermédiaire : niveau 1<br /><br />


                            <u>IOBSP (Intermédiaire en Opérations de Banque et en Services de Paiements)</u> :<br /> catégorie d’intermédiaire (courtier, mandataire à titre exclusif /non exclusif ou mandataire d’IOBSP)
                            Service de conseil sur des contrats de crédits immobiliers : Conseil indépendant avec rémunération sous forme d’honoraire<br /><br />


                            L’(ou les) activité(s) d’IAS ou (et) d’IOBSP est (sont) contrôlable(s) par l’Autorité de Contrôle Prudentiel et de Résolution (ACPR)<br />
                            adresses courrier : 4 Place de Budapest, 75436 PARIS cedex 09 <br /><br /> site internet : <a href="https://acpr.banque-france.fr">acpr.banque-france.fr/</a><br /><br />

                            Adhésion à l’association professionnelle agréée par l’ACPR : ANACOFI-COURTAGE<br /><br />
                            Votre conseiller (ou intermédiaire) s’est engagé à respecter intégralement le Code de Bonne Conduite de l’ANACOFI-CIF disponible au siège de l’association ou sur www.anacofi.asso.fr ou https://www.anacofi-cif.fr/.<br /><br />

                            Il est par ailleurs :
                            Mandataire en démarchage financier.
                            Cette activité est contrôlable par l’AMF et l’ACPR.
                        </Typography>
                    </CardContent>
                </Collapse>
            </Card>

        </>
    )
}