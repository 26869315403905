import { Button, Box, Tabs, Tab, Toolbar, Typography } from "@mui/material";

import { useState } from "react";
import { Link } from "react-router-dom";
import IncomeDisplay from "../../components/display/income-display/incomeDisplay";
import { useData } from "../../utils/firestore-database/providers/dataProvider";

export default function Income() {
  const { incomeData } = useData();

  return (
    <>
      <Typography variant="h4">VOS REVENUS</Typography>
      <Toolbar />
      <IncomeDisplay />;
    </>
  );
}
