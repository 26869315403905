import { FormControl, InputLabel, Select, MenuItem } from "@mui/material"

export default function MaritalContract({ data, setData }) {

    return (
        <>
            <FormControl sx={{ m: 0, width: '100%' }}>
                <InputLabel id="select-marital-contract-label">Régime Matrimonial</InputLabel>
                <Select
                    labelId="select-marital-contract-label"
                    id="select-marital-contract"
                    value={data.maritalContract || ""}
                    label="Régime Matrimonial"
                    autoWidth
                    onChange={setData}>
                    <MenuItem value={'community'}>
                        Sans Contrat de Mariage / Communauté
                    </MenuItem>
                    <MenuItem value={'separation'}>
                        Séparation de Bien
                    </MenuItem>
                    <MenuItem value={"universal"}>
                        Communauté Universelle
                    </MenuItem>
                    <MenuItem value={"participation"}>
                        Participation Aux Acquêts
                    </MenuItem>
                    <MenuItem value={"other"}>
                        Autre
                    </MenuItem>
                </Select>
            </FormControl>
        </>
    )
}