import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

export default function SelectIncomeType({ data, setData }) {
  return (
    <>
      <FormControl sx={{ m: 0, width: "100%" }}>
        <InputLabel id="select-income-label">Type</InputLabel>
        <Select
          labelId="select-income-label"
          id="select-income"
          name="type"
          value={data.type || ""}
          label="Type"
          autoWidth
          onChange={setData}
        >
          <MenuItem value={"TS"}>
            Traitement et Salaire
          </MenuItem>
          <MenuItem value={"BIC/BNC"}>BIC / BNC</MenuItem>
          <MenuItem value={"Locatifs"}>Revenus Locatifs</MenuItem>
          <MenuItem value={"Dividendes/RCM"}>Dividendes / RCM</MenuItem>
          <MenuItem value={"Autre"}>Autre</MenuItem>
        </Select>
      </FormControl>
    </>
  );
}
