import { useState, Fragment } from "react";
import { getFirestore } from "firebase/firestore";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { useAuth } from "../../../utils/firestore-database/providers/authProvider";
import firebaseApp from "../../../utils/firestore-database/firebaseConfig";
import {
  Button,
  TextField,
  Grid,
  Toolbar,
  CircularProgress,
} from "@mui/material";
import { useEffect } from "react";
import SelectAddCash from "../../../components/select/addProperty/selectAddCash";
import SumPropertyCalcul from "../../../utils/calculator/sumPropertyCalcul";
import onlyNumberKeyDown from "../../../utils/handleKeyDown/onlyNumberKeyDown";
import { useData } from "../../../utils/firestore-database/providers/dataProvider";
import AddPropertiesButtons from "../../../components/button/addPropertiesButtons";
import AddPropertiesWrapper from "../../../components/wraper/addPropertiesWrapper";
import { useNavigate } from "react-router-dom";

export default function AddOther() {
  const { otherData, otherLoading, putDataInFirebase, putSumInFirebase } =
    useData();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    setData(otherData);
    setLoading(otherLoading);
  }, [otherData, otherLoading]);

  const handleClick = () => {
    setData([...data, {}]);
  };

  const handleChange = (e, i) => {
    const { name, value } = e.target;

    const onchangeVal = [...data];

    name === "amount"
      ? (onchangeVal[i][name] = parseInt(value))
      : (onchangeVal[i][name] = value);

    setData(onchangeVal);
  };

  const handleDelete = (i) => {
    const deleteVal = [...data];
    deleteVal.splice(i, 1);
    setData(deleteVal);
  };

  function handleSubmit(event) {
    event.preventDefault();

    const sum = SumPropertyCalcul(data, "Autre");

    putSumInFirebase(sum, "data", "patrimoine", "sum", "other");
    putDataInFirebase(data, "data", "patrimoine", "other");
    navigate("/patrimoine");
  }

  return (
    <>
      <Toolbar />
      {loading ? (
        <>
          <CircularProgress />
        </>
      ) : (
        <>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              {data.map((item, index) => (
                <Fragment key={`fragment ${index}`}>
                  <AddPropertiesWrapper onClick={() => handleDelete(index)}>
                    <Grid item xs={12}>
                      <TextField
                        name="type"
                        value={item.type || ""}
                        id="1"
                        label="Type"
                        variant="outlined"
                        onChange={(e) => handleChange(e, index)}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        required
                        name="amount"
                        value={item.amount || ""}
                        onKeyDown={onlyNumberKeyDown}
                        id="1"
                        label="Valeur"
                        variant="outlined"
                        onChange={(e) => handleChange(e, index)}
                      />
                    </Grid>
                  </AddPropertiesWrapper>
                </Fragment>
              ))}
              <AddPropertiesButtons onClick={handleClick} />
            </Grid>
          </form>
        </>
      )}
    </>
  );
}
