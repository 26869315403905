import { FormControl, InputLabel, Select, MenuItem } from "@mui/material"

export default function SelectAddEmployee({ data, setData }) {
    return (
        <>
            <FormControl sx={{ m: 0, width: '100%' }}>
                <InputLabel id="select-employee-label">Type</InputLabel>
                <Select
                    labelId="select-employee-label"
                    id="select-employee"
                    name="type"
                    value={data.type || ''}
                    label="Type"
                    autoWidth
                    onChange={setData}>
                    <MenuItem value={'PEE'}>
                        PEE
                    </MenuItem>
                    <MenuItem value={'PERCO'}>
                        PERCO
                    </MenuItem>
                    <MenuItem value={"PERECO"}>
                        PERECO
                    </MenuItem>
                    <MenuItem value={"Madelin"}>
                        Contrat Madelin
                    </MenuItem>
                </Select>
            </FormControl>
        </>
    )
}