import {
  Button,
  CardActionArea,
  CardContent,
  Typography,
  Card,
  Grid,
} from "@mui/material";
import { Link } from "react-router-dom";

const cardList = [
  {
    id: 1,
    name: "cash",
    title: "Liquidités",
    subtitle: "Livret A, Comptes courant, PEL, LDD etc",
    link: "addCash",
  },
  {
    id: 2,
    name: "estate",
    title: "Immobilier",
    subtitle: "Résidence principale, Immobilier locatif, SCPI etc",
    link: "addEstate",
  },
  {
    id: 3,
    name: "financial",
    title: "Placement Financier",
    subtitle: "Compte Titre, PEA, Assurance vie etc",
    link: "addFinancial",
  },
  {
    id: 4,
    name: "employeeSaving",
    title: "Placement salarial",
    subtitle: "PEE, PERCO, ",
    link: "addEmployee",
  },
  {
    id: 5,
    name: "other",
    title: "Autre",
    subtitle: "Autres Placements",
    link: "addOther",
  },
];

export default function AddPropertyCard() {
  return (
    <>
      <Grid container spacing={1}>
        {cardList.map((item) => (
          <Grid item xs={12} md={6} key={item.id}>
            <Card>
              <CardActionArea component={Link} to={item.link}>
                <CardContent>
                  <Typography variant="h4"> {item.title} </Typography>
                  <Typography variant="body2"> {item.subtitle}</Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  );
}
