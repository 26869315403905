import { Grid, Paper, Stack, Button } from "@mui/material";

export default function AddPropertiesButtons({ onClick }) {
  return (
    <Grid item xs={12} md={2}>
      <Paper sx={{ mt: 2 }}>
        <Stack spacing={1}>
          <Button
            variant="contained"
            onClick={onClick}
            sx={{ padding: "30px 20px" }}
          >
            Ajouter
          </Button>
          <Button
            variant="contained"
            type="submit"
            sx={{ padding: "30px 20px" }}
          >
            Enregistrer
          </Button>
        </Stack>
      </Paper>
    </Grid>
  );
}
