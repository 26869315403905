import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import AssignmentIcon from "@mui/icons-material/Assignment";
import FamilyRestroomIcon from "@mui/icons-material/FamilyRestroom";
import { List, ListItem, Toolbar, Divider } from "@mui/material";
import { Link } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import { styled, useTheme } from "@mui/material/styles";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import EuroIcon from "@mui/icons-material/Euro";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import { IconButton } from "@mui/material";
import { useState } from "react";
import MuiDrawer from "@mui/material/Drawer";

const drawerWidth = 300;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(10),
      },
    }),
  },
}));

const menuDrawer = [
  {
    id: 1,
    name: "Accueil",
    text: "Accueil",
    icon: <AssignmentIcon />,
    link: "/",
  },
  {
    id: 2,
    name: "patrimoine",
    text: "Patrimoine",
    icon: <LeaderboardIcon />,
    link: "patrimoine",
  },
  {
    id: 3,
    name: "income",
    text: "Revenus",
    icon: <EuroIcon />,
    link: "income",
  },
  {
    id: 4,
    name: "expense",
    text: "Charges",
    icon: <CurrencyExchangeIcon />,
    link: "expense",
  },
  {
    id: 5,
    name: "vosinformations",
    text: "Informations Personnelles",
    icon: <InfoIcon />,
    link: "infospersos",
  },
  // {
  //     id:6,
  //     name:"account",
  //     text:"Compte",
  //     icon:<LeaderboardIcon />,
  //     link:'acount'
  // },
  // {
  //     id:7,
  //     name:"succession",
  //     text:"Succession",
  //     icon:<FamilyRestroomIcon />,
  //     link:'succession'
  // }
];

export default function MainDrawer({ open, handleDrawerClose }) {
  const theme = useTheme();
  // const [open, setOpen] = useState(false);

  // const handleDrawerOpen = () => {
  //   setOpen(true);
  // };

  // const handleDrawerClose = () => {
  //   setOpen(false);
  // };

  return (
    // <Drawer
    //   sx={{
    //     flexShrink: 0,
    //   }}
    //   variant="persistent"
    //   anchor="left"
    //   open={open}
    // >
    <Drawer variant="permanent" open={open}>
      {/* <DrawerHeader>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === "ltr" ? (
            <ChevronLeftIcon />
          ) : (
            <ChevronRightIcon />
          )}
        </IconButton>
      </DrawerHeader> */}
      <Toolbar
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          px: [1],
        }}
      >
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeftIcon />
        </IconButton>
      </Toolbar>
      <Divider />
      <List>
        {menuDrawer.map((item, index) => (
          <ListItem key={item.name}>
            <ListItemButton component={Link} to={item.link}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
}

// <ListSubheader component="div" inset>
// Saved reports
// </ListSubheader>
// <ListItemButton component={Link} to="situationfamille">
//     <ListItemIcon>
//          <AssignmentIcon />
//     </ListItemIcon>
//     <ListItemText primary="Current month" />
// </ListItemButton>
// <ListItemButton component={Link} to="succession">
//     <ListItemIcon>
//         <AssignmentIcon />
//      </ListItemIcon>
//     <ListItemText primary="Last quarter" />
// </ListItemButton>
// <ListItemButton>
//     <ListItemIcon>
//         <AssignmentIcon />
//     </ListItemIcon>
//     <ListItemText primary="Year-end sale" />
// </ListItemButton>
