import { useState, Fragment } from "react";
import { getFirestore } from "firebase/firestore";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { useAuth } from "../../../utils/firestore-database/providers/authProvider";
import firebaseApp from "../../../utils/firestore-database/firebaseConfig";
import {
  Button,
  TextField,
  Grid,
  Toolbar,
  CircularProgress,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useEffect } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useData } from "../../../utils/firestore-database/providers/dataProvider";

import AgeCalculator from "../../../utils/calculator/ageCalulator";
import SelectTypeEstate from "../../../components/select/addProperty/selectAddEstate/selectTypeEstate";
import SelectLocationTypeEstate from "../../../components/select/addProperty/selectAddEstate/selectLocationTypeEstate";
import SelectInvestTypeEstate from "../../../components/select/addProperty/selectAddEstate/selectInvestTypeEstate";
import SelectDetentionMode from "../../../components/select/addProperty/selectAddEstate/selectDetentionMode";
import { SettingsSystemDaydream } from "@mui/icons-material";
import SumPropertyCalcul from "../../../utils/calculator/sumPropertyCalcul";
import onlyNumberKeyDown from "../../../utils/handleKeyDown/onlyNumberKeyDown";
import AddPropertiesButtons from "../../../components/button/addPropertiesButtons";
import AddPropertiesWrapper from "../../../components/wraper/addPropertiesWrapper";
import { useNavigate } from "react-router-dom";

export default function AddEstate() {
  const [data, setData] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const { estateData, putDataInFirebase, estateLoading, putSumInFirebase } =
    useData();

  useEffect(() => {
    setData(estateData);
    setLoading(estateLoading);
  }, [estateData, estateLoading]);

  const handleClick = () => {
    setData([...data, {}]);
  };

  const handleChange = (e, i) => {
    const { name, value } = e.target;
    const onchangeVal = [...data];

    name === "amount"
      ? (onchangeVal[i][name] = parseInt(value))
      : (onchangeVal[i][name] = value);

    setData(onchangeVal);
  };

  const handleCheck = (e, i) => {
    const { name, checked } = e.target;
    const onchangeVal = [...data];

    onchangeVal[i][name] = checked;
    setData(onchangeVal);
    setIsChecked(!isChecked);
  };

  const handleDelete = (i) => {
    const deleteVal = [...data];
    deleteVal.splice(i, 1);
    setData(deleteVal);
  };

  // function validateNumber(event) {
  //     const input = event.target.value;
  //     if (isNaN(input)) {
  //         event.target.value = ""; // Efface la saisie si ce n'est pas un nombre
  //     }
  // }

  function handleDateChange(e, index) {
    const date = dayjs(e);

    const year = date.year();
    const month = date.month();
    const day = date.date();

    const dateString = e.toString();
    const age = AgeCalculator(day, month, year);

    const onchangeVal = [...data];
    onchangeVal[index]["date"] = dateString;

    const onchangeVal2 = [...data];
    onchangeVal2[index]["age"] = age;
    setData(onchangeVal, onchangeVal2);
  }

  function EstateType(item, index) {
    switch (item.type) {
      case "Bien Locatif":
        return (
          <Grid item xs={12}>
            <SelectLocationTypeEstate
              data={item}
              setData={(e) => handleChange(e, index)}
            />
          </Grid>
        );
      case "Investissement Immobilier":
        return (
          <Grid item xs={12}>
            <SelectInvestTypeEstate
              data={item}
              setData={(e) => handleChange(e, index)}
            />
          </Grid>
        );
      default:
        return null;
    }
  }

  function handleSubmit(event) {
    event.preventDefault();

    const sum = SumPropertyCalcul(data, "Immobilier");

    putSumInFirebase(sum, "data", "patrimoine", "sum", "estate");
    putDataInFirebase(data, "data", "patrimoine", "estate");
    navigate("/patrimoine");
  }

  return (
    <>
      <Toolbar />
      {loading ? (
        <>
          <CircularProgress />
        </>
      ) : (
        <>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              {data.map((item, index) => (
                <Fragment key={`fragment ${index}`}>
                  <AddPropertiesWrapper
                    onClick={() => handleDelete(index)}
                    md={6}
                  >
                    <Grid item xs={12}>
                      <SelectTypeEstate
                        data={item}
                        setData={(e) => handleChange(e, index)}
                      />
                    </Grid>
                    {EstateType(item, index)}
                    <Grid item xs={12} md={6}>
                      <TextField
                        required
                        name="amount"
                        type="number"
                        value={item.amount || ""}
                        onKeyDown={onlyNumberKeyDown}
                        label="Valeur du bien"
                        variant="outlined"
                        onChange={(e) => handleChange(e, index)}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        name="buyingPrice"
                        type="number"
                        value={item.buyingPrice || ""}
                        onKeyDown={(e) => onlyNumberKeyDown(e)}
                        label="Prix d'achat"
                        variant="outlined"
                        onChange={(e) => handleChange(e, index)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name="bank"
                        value={item.bank || ""}
                        label="adresse"
                        variant="outlined"
                        onChange={(e) => handleChange(e, index)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale={"fr"}
                      >
                        <DatePicker
                          label="Année d'Achat"
                          value={dayjs(item.date)}
                          onChange={(e) => handleDateChange(e, index)}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12}>
                      <SelectDetentionMode
                        data={item}
                        setData={(e) => handleChange(e, index)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="sci"
                              checked={item.sci}
                              onChange={(e) => handleCheck(e, index)}
                            />
                          }
                          label="Ce bien est détenu à travers une SCI"
                        />
                      </FormGroup>
                    </Grid>
                  </AddPropertiesWrapper>
                </Fragment>
              ))}
              <AddPropertiesButtons onClick={handleClick} />
            </Grid>
          </form>
        </>
      )}
    </>
  );
}
