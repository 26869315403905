import { Fragment, useState } from "react";
import { getFirestore } from "firebase/firestore";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { useAuth } from "../../../utils/firestore-database/providers/authProvider";
import firebaseApp from "../../../utils/firestore-database/firebaseConfig";
import {
  Button,
  TextField,
  Grid,
  Toolbar,
  CircularProgress,
  useFormControl,
  Paper,
  Stack,
} from "@mui/material";
import { useEffect } from "react";
import SelectAddCash from "../../../components/select/addProperty/selectAddCash";
import SumPropertyCalcul from "../../../utils/calculator/sumPropertyCalcul";
import onlyNumberKeyDown from "../../../utils/handleKeyDown/onlyNumberKeyDown";
import CashInput from "../../../components/input/select-property-inputs/select-cash-inputs/cashInput";
import { ConstructionOutlined } from "@mui/icons-material";
import { useData } from "../../../utils/firestore-database/providers/dataProvider";
import AddPropertiesButtons from "../../../components/button/addPropertiesButtons";
import AddPropertiesWrapper from "../../../components/wraper/addPropertiesWrapper";
import { useNavigate } from "react-router-dom";

export default function AddCash() {
  const { cashData, putDataInFirebase, cashLoading, putSumInFirebase } =
    useData();

  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  // On pourrait peut être aussi faire appel diretement appel à la fonction cashref dans le useData ptet, à voir si ça fait plus d'appel
  useEffect(() => {
    setData(cashData);
    setLoading(cashLoading);
  }, [cashData, cashLoading]);
  // const [loading, setLoading] = useState(true)
  // const [data2,setData2]=useState({})
  // const [numberOfAsset,setNumberOfAsset]=useState(0)

  const handleClick = () => {
    setData([...data, {}]);

    // setNumberOfAsset(numberOfAsset + 1)
  };

  const handleChange = (e, i) => {
    const { name, value, label } = e.target;

    const onchangeVal = [...data];
    // onchangeVal[i][name]=value

    name === "amount"
      ? (onchangeVal[i][name] = parseInt(value))
      : (onchangeVal[i][name] = value);
    // onchangeVal[i][name]=value
    setData(onchangeVal);
  };

  // function handleChange1(e, index){

  //     const {name,value}=e.target
  //     const nameIndex = `cash ${index}`

  //     setData({...data2, [nameIndex]:{
  //         ...data2[nameIndex], [name]:value}})

  // }

  const handleDelete = (i) => {
    const deleteVal = [...data];
    deleteVal.splice(i, 1);
    setData(deleteVal);

    // setNumberOfAsset(numberOfAsset -1)
  };

  //   const cashInput = [];

  //   function handleDelete(cashNum){
  //     setNumberOfAsset(numberOfAsset -1)

  //     setData2(prevData => {
  //         const newData = { ...data2 };
  //         delete newData[cashNum];
  //         return newData;
  //       });

  //   }

  // for (let i = 0; i < numberOfAsset; i++) {

  //     const cashNum = `cash ${i}`
  //     const childSurname = `childSurname ${i}`
  //     const childDate = `childDate ${i}`
  //     const childAge = `childAge ${i}`

  //     const value = data2[cashNum] ? data2[cashNum].value : ''
  //     const type = data2[cashNum] ? data2[cashNum].type : ''
  //     const bank = data2[cashNum] ? data2[cashNum].bank : ''
  //     // const surname = data[childNum] ? data[childNum].surName : ''
  //     // const checked = data[childNum] ? data[childNum].isDependant : false
  //     // const date = data[childNum] ? data[childNum].date : false

  //     // const isDependant = data[childNum].isDependant

  //     cashInput.push(
  //         <>
  //         <Grid item xs={4}>
  //         <Box>
  //             <Grid container spacing={1}>
  //                 <Grid item xs={6}>
  //                     <TextField  value = {type} name="type"  id="1" label="Type" variant="outlined" onChange={e => handleChange1(e, i)}/>
  //                 </Grid>
  //                 <Grid item xs={6}>
  //                     <TextField  value = {value} name="value" id="1" label="Solde" variant="outlined" onChange={e => handleChange1(e, i)}/>
  //                 </Grid>
  //                 <Grid item xs={12}>
  //                     <TextField  value = {bank} name="bank" id="1" label="Organisme" variant="outlined" onChange={e => handleChange1(e, i)}/>
  //                 </Grid>
  //                 {/* <Grid item xs={4}>
  //                     <TextField  name={"nomp " + index} id="1" label="Nom" variant="outlined" onChange={e => handleChange(e, index)}/>
  //                 </Grid>                        */}
  //             </Grid>
  //             {/* <Button onClick={() => setNumberOfAsset(numberOfAsset -1)}>Delete</Button> */}
  //             <Button onClick={() => handleDelete(cashNum)}>Deletus</Button>
  //         </Box>
  //     </Grid>
  //     </>
  // );
  // }

  function handleSubmit(event) {
    event.preventDefault();
    const sum = SumPropertyCalcul(data, "Liquidités");
    // console.log(sum)
    putSumInFirebase(sum, "data", "patrimoine", "sum", "cash");
    putDataInFirebase(data, "data", "patrimoine", "cash");
    navigate("/patrimoine");
  }

  return (
    <>
      <Toolbar />
      {loading ? (
        <>
          <CircularProgress />
        </>
      ) : (
        <>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              {data.map((item, index) => (
                <Fragment key={`fragment ${index}`}>
                  <AddPropertiesWrapper onClick={() => handleDelete(index)}>
                    <Grid item xs={12} md={6}>
                      <SelectAddCash
                        data={item}
                        setData={(e) => handleChange(e, index)}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        required
                        name="amount"
                        value={item.amount || ""}
                        onKeyDown={onlyNumberKeyDown}
                        id="1"
                        label="Solde"
                        variant="outlined"
                        onChange={(e) => handleChange(e, index)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name="bank"
                        value={item.bank || ""}
                        id="1"
                        label="Organisme"
                        variant="outlined"
                        onChange={(e) => handleChange(e, index)}
                      />
                    </Grid>
                  </AddPropertiesWrapper>
                </Fragment>
              ))}
              {/* {cashInput} */}
              <AddPropertiesButtons onClick={handleClick} />
            </Grid>
          </form>
        </>
      )}
    </>
  );
}
