export default function dataWithOtherTransformed(data) {

    const transformedData = data.map(item => {
        const newItem = { ...item };
        newItem.type = item.type === "Autre" ? item.otherType : item.type;
        // delete newItem.type;
        // delete newItem.otherType;
        return newItem;
    });

    return transformedData


}