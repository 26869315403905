import { FormControl, InputLabel, Select, MenuItem } from "@mui/material"

export default function SelectMaritalStatus({ maritalStatus, setMaritalStatus }) {

    return (
        <>
            <FormControl sx={{ m: 0, width: '100%' }}>
                <InputLabel id="select-marital-status-label">Situation maritale</InputLabel>
                <Select
                    labelId="select-marital-status-label"
                    id="select-marital-statut"
                    value={maritalStatus || ""}
                    label="Situation maritale"
                    autoWidth
                    onChange={setMaritalStatus}>
                    <MenuItem value={'single'}>
                        Célibat
                    </MenuItem>
                    <MenuItem value={'mariage'}>
                        Mariage
                    </MenuItem>
                    <MenuItem value={"pacs"}>
                        PACS
                    </MenuItem>
                    <MenuItem value={"concubin"}>
                        Concubinage
                    </MenuItem>
                    <MenuItem value={"veuf"}>
                        Veuvage
                    </MenuItem>
                </Select>
            </FormControl>
        </>
    )
}