import { Container, Toolbar, Box, CircularProgress } from "@mui/material";
import { Link, Outlet, redirect, useNavigate } from "react-router-dom";
import MainDrawer from "../components/drawer/mainDrawer";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
  onAuthStateChanged,
  signInWithRedirect,
} from "firebase/auth";
import { useState, useEffect } from "react";
import { initializeApp } from "firebase/app";
import AuthProvider from "../utils/firestore-database/providers/authProvider";
import { useAuth } from "../utils/firestore-database/providers/authProvider";
import NeedConnexion from "./needConnexion";
import Connexion from "./connexion";
import SignInPage from "./connexion/signInPage";
import MainAppBar from "../components/mainAppBar";

function App() {
  const { currentUser } = useAuth();

  // ça c'est pour la navigation mais on dirait que y a mieux à faire avec redirect dans un loader de react router ou jsais pas quoi https://reactrouter.com/en/main/fetch/redirect
  const navigate = useNavigate();

  useEffect(() => {
    if (!currentUser) {
      navigate("/signIn");
    }
  }, [currentUser, navigate]);

  return (
    <>
      {!currentUser ? (
        <CircularProgress />
      ) : (
        <Box sx={{ display: "flex" }}>
          <MainAppBar />
          <Box
            component="main"
            sx={{
              // backgroundImage: "linear-gradient(180deg, #CEE5FD, #FFF)",

              backgroundColor: (theme) =>
                theme.palette.mode === "light"
                  ? theme.palette.grey[200]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: "100vh",
              overflow: "auto",
            }}
          >
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
              <Toolbar />
              <Outlet />
            </Container>
          </Box>
        </Box>
      )}
    </>
  );
}

export default App;
