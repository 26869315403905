import { Typography, Box, Button } from "@mui/material";
import { useRef, useState } from "react";
// import '../../utils/SignaturePad.css';

import SignatureCanvas from 'react-signature-canvas'

export default function SignaturePdf({ setSignatureImageUrl, setIsSigned }) {

    //Le css est dans le signaturepad.css
    const sigCanvas = useRef({});

    const [isCanvasEmpty, setIsCanvasEmpty] = useState(true);

    const handleEnd = () => {
        setIsCanvasEmpty(sigCanvas.current.isEmpty());
    };

    const clear = () => {
        sigCanvas.current.clear();
        setIsCanvasEmpty(true)
    }

    const save = () => {
        setSignatureImageUrl(sigCanvas.current.getTrimmedCanvas().toDataURL('image/png'));
        setIsSigned(true)
    };


    return (
        <>
            <Box>
                <SignatureCanvas
                    ref={sigCanvas}
                    onEnd={handleEnd}
                    canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }}
                />
            </Box>

            <Button onClick={clear}>Effacer</Button>
            <Button disabled={isCanvasEmpty} onClick={save}>Signer</Button>
        </>
    )
}