import {
  Grid,
  TextField,
  Box,
  Typography,
  Toolbar,
  Button,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import AgeCalculator from "../../../utils/calculator/ageCalulator";
import "dayjs/locale/en-gb";
import fr from "dayjs/locale/fr";

export default function SpouseInput({ data, setData }) {
  const name = data.spouse ? data.spouse.name : "";
  const surname = data.spouse ? data.spouse.surname : "";
  const occupation = data.spouse ? data.spouse.occupation : "";
  const date = data.spouse ? data.spouse.date : "";

  function handleSpouseDateChange(newValue) {
    const date = dayjs(newValue);

    const year = date.year();
    const month = date.month();
    const day = date.date();

    const dateString = newValue.toString();
    const age = AgeCalculator(day, month, year);
    //useState pour l'age
    setData({
      ...data,
      spouse: { ...data.spouse, date: dateString, age: age },
    });
  }

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5">Votre Conjoint</Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          value={name}
          id="name"
          label="Nom de votre conjoint"
          variant="outlined"
          onChange={(e) =>
            setData({
              ...data,
              spouse: { ...data.spouse, name: e.target.value },
            })
          }
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          value={surname}
          id="surname"
          label="Prénom de votre conjoint"
          variant="outlined"
          onChange={(e) =>
            setData({
              ...data,
              spouse: { ...data.spouse, surname: e.target.value },
            })
          }
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"fr"}>
          <DatePicker
            label="Date de naissance"
            value={dayjs(date)}
            onChange={handleSpouseDateChange}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          value={occupation}
          id="name"
          label="Profession"
          variant="outlined"
          onChange={(e) =>
            setData({
              ...data,
              spouse: { ...data.spouse, occupation: e.target.value },
            })
          }
        />
      </Grid>
    </>
  );
}
