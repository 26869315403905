export function TotalDonation({donationData}){

  const totalDonationArray = donationData.map(object=>
    parseInt(object.amount)
    )

    const totalDonation = totalDonationArray.reduce(
      (acc, value) => acc + value, 0
  )

    return(totalDonation)


}

export function CalculImputationReserve({donationData}){

   

    const totalDonationChildFilter = donationData.filter(object=>
        object.quality === 'child')

      const totalDonationChildObject = totalDonationChildFilter.reduce((acc, curr) => {
        // Vérifiez si l'objet avec le même nom existe déjà dans l'accumulateur
        if (acc[curr.fname]) {
          // Si oui, ajoutez le montant actuel à celui existant
          acc[curr.fname] += parseInt(curr.amount || 0); // Utilisez parseFloat pour convertir la chaîne de caractères en nombre
        } else {
          // Sinon, initialisez le montant pour ce nom
          acc[curr.fname] = parseInt(curr.amount || 0); // Utilisez parseFloat pour convertir la chaîne de caractères en nombre
        }
        return acc;
      }, {});

      //ATTENTION ça c'est fait par chatGPT alors ce serait bien de revérifier tout ça 
      const totalDonationChildArray = Object.entries(totalDonationChildObject).map(([name, value]) => ({
        name,
        value
      }));

      return JSON.stringify(totalDonationChildArray)

}

//C'est les donations qui s'appliquent uniquement sur la qd (ie pas les enfants)
export function CalculImputationQd({donationData}){

   
    const totalDonationChildFilter = donationData.filter(object=>
        object.quality !== 'child')

    const totalDonationParse = totalDonationChildFilter.map(object=>
            parseInt(object.amount || 0)
            )
    
  
      const totalDonationQd = totalDonationParse.reduce(
          (acc, value) => acc + value, 0
      )

  
    return totalDonationQd

}