export function DMTG(value, familialStatus)
{
    const trueValue = value-100000
    let tax = 0

    const tranche = [0, 8072, 12109, 15932, 552324, 902838, 1805677]
    const taux = [0.05, 0.1, 0.15, 0.2, 0.3, 0.4, 0.45];

    if (trueValue <= 0) {
        return 0;
    } else if (trueValue <= tranche[1]) {
        tax = trueValue * taux[0];
    } else if (trueValue <= tranche[2]) {
        tax = (tranche[1] * taux[0]) + ((trueValue - tranche[1]) * taux[1]);
    } else if (trueValue <= tranche[3]) {
        tax = (tranche[1] * taux[0]) + ((tranche[2] - tranche[1]) * taux[1]) + ((trueValue - tranche[2]) * taux[2]);
    } else if (trueValue <= tranche[4]) {
        tax = (tranche[1] * taux[0]) + ((tranche[2] - tranche[1]) * taux[1]) + ((tranche[3] - tranche[2]) * taux[2]) + ((trueValue - tranche[3]) * taux[3]);
    } else if (trueValue <= tranche[5]) {
        tax = (tranche[1] * taux[0]) + ((tranche[2] - tranche[1]) * taux[1]) + ((tranche[3] - tranche[2]) * taux[2]) + ((tranche[4] - tranche[3]) * taux[3]) + ((trueValue - tranche[4]) * taux[4]);
    } else if (trueValue <= tranche[6]) {
        tax = (tranche[1] * taux[0]) + ((tranche[2] - tranche[1]) * taux[1]) + ((tranche[3] - tranche[2]) * taux[2]) + ((tranche[4] - tranche[3]) * taux[3]) + ((tranche[5] - tranche[4]) * taux[4]) + ((trueValue - tranche[5]) * taux[5]);
    } else {
        tax = (tranche[1] * taux[0]) + ((tranche[2] - tranche[1]) * taux[1]) + ((tranche[3] - tranche[2]) * taux[2]) + ((tranche[4] - tranche[3]) * taux[3]) + ((tranche[5] - tranche[4]) * taux[4]) + ((tranche[6] - tranche[5]) * taux[5]) + ((trueValue - tranche[6]) * taux[6]);
    }

    
    return parseInt(tax)

    

}