export default function SumPropertyCalcul(data, dataName) {


    const initialValue = 0
    let sumReduce = 0

    data ?
        sumReduce = data.reduce(
            (accumulateur, valeurCourante) => accumulateur + valeurCourante.amount,
            initialValue)
        : sumReduce = 0

    const sumObject = { type: dataName, amount: sumReduce }

    console.log(sumObject)

    return sumObject
}