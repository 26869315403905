import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

export default function SelectLocationTypeEstate({ data, setData }) {
  return (
    <>
      <FormControl sx={{ m: 0, width: "100%" }}>
        <InputLabel id="select-location-type-estate-label">
          Mode de location
        </InputLabel>
        <Select
          labelId="select-location-type-estate-label"
          id="select-location-type-estate"
          name="locationType"
          value={data.locationType || ""}
          label="Mode de location"
          autoWidth
          onChange={setData}
        >
          <MenuItem value={"Location Nue"}>Location Nue</MenuItem>
          <MenuItem value={"LMNP"}>LMNP</MenuItem>
          <MenuItem value={"Pinel"}>Pinel</MenuItem>
          <MenuItem value={"De Normandie"}>De Normandie</MenuItem>
          <MenuItem value={"Malraux"}>Malraux</MenuItem>
          <MenuItem value={"Autre"}>Autre</MenuItem>
        </Select>
      </FormControl>
    </>
  );
}
