



//Il faudra faire l'ajout de la réunion fictive ou alors on le met dans patrimoineTotal
export function Qd(childNumber, maritalStatus, patrimoineTotal, usufructChoice, donationTotal){

    const reunionFictive = patrimoineTotal + donationTotal
    let qd = 0

    
        switch(childNumber){
            case 1 : 
            qd = reunionFictive/2
            break;

            case 2 : 
            qd = reunionFictive/3;
            break;

            default:
                if (childNumber >= 3) {
                    qd = reunionFictive / 4;
                } else {
                    qd = reunionFictive;
                }
        }

    
    return parseInt(qd)
}

export function ResteQd(qd, imputationQd){
    let restQd = qd - imputationQd

    if(restQd < 0){
        restQd  = 0
    }

    return restQd
}

function Barème669CGI(PPvalue, age){

    let UFvalue=PPvalue

    if (age < 21) {
        UFvalue=PPvalue*(90/100);
    } else if (age >= 21 && age < 31) {
        UFvalue=PPvalue*(80/100);
    } 
     else if (age >= 31 && age < 41) {
        UFvalue=PPvalue*(70/100);
    } 
     else if (age >= 41 && age < 51) {
        UFvalue=PPvalue*(60/100);
    } 
     else if (age >= 51 && age < 61) {
        UFvalue=PPvalue*(50/100);
    } 
     else if (age >= 51 && age < 71) {
        UFvalue=PPvalue*(40/100);
    } 
     else if (age >= 61 && age < 81) {
        UFvalue=PPvalue*(30/100);
    } 
     else if (age >= 71 && age < 91) {
        UFvalue=PPvalue*(20/100);
    } 
    else {
        UFvalue=PPvalue*(10/100);
    }

    return UFvalue
}

export function DroitDuConjoint(patrimoineTotal, usufructChoice, age, qdRestante){
    
    let droitDuconjoint = patrimoineTotal/4

    if(qdRestante<droitDuconjoint){
        droitDuconjoint=qdRestante
    }

    if(usufructChoice){
        droitDuconjoint = Barème669CGI(patrimoineTotal, age)
    }

    return droitDuconjoint

}


export function DroitEnfants(childName, childNumber, patrimoineTotal, droitDuConjoint, donationChild){
    
    const donation = donationChild !== null ? JSON.parse(donationChild) : []

    const restePatrimoine = patrimoineTotal - droitDuConjoint

    //reserveIndiv est passé avec la qd
    // const resteQd = reserveIndiv - droitDuConjoint
    
    // const total = restePatrimoine + resteQd
    // console.log(qd+" "+restePatrimoine+" "+resteQd+ " "+total)
    let reserve = restePatrimoine/childNumber


    // const resteEnfants = donation.map(object=>{
    //     if(object.map === childName){
    //         return {name: object.amount}
    //     } else {
    //         return {name: object.amount}
    //     }
    // })


    


    // if(childName === donation.name){
    //     reserve = reserve - donation.value
    // } 

    // console.log(childNumber)

    return reserve
    
}