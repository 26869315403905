import { Button, Box, Tabs, Tab, Toolbar, Typography } from "@mui/material";

import { useState } from "react";
import { Link } from "react-router-dom";
import IncomeDisplay from "../../components/display/income-display/incomeDisplay";
import ExpenseDisplay from "../../components/display/expense-display/expenseDisplay";

export default function Expense() {
  return (
    <>
      <Typography variant="h4">VOS CHARGES</Typography>
      <Toolbar />
      <ExpenseDisplay />
    </>
  );
}
