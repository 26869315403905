import {
  Button,
  Grid,
  TextField,
  Toolbar,
  Box,
  CircularProgress,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import dayjs from "dayjs";
import AgeCalculator from "../../../utils/calculator/ageCalulator";
import SelectMaritalStatus from "../../select/select-personnal/selectMaritalStatus";
import SelectChildNumber from "../../select/selectChildNumber";
import SelectGender from "../../select/selectGender";
import MaritalContract from "../../select/select-personnal/maritalContract";
import fr from "dayjs/locale/fr";
import { useAuth } from "../../../utils/firestore-database/providers/authProvider";
import SelectIsChild from "../../select/select-personnal/selectIsChild";
// import { identite } from "../utils/GlobalTheme/Data/userData";

export default function CustomerInfo({ data, setData, loading }) {
  // const {currentUser, loading} = useAuth();

  //Pour mettre en useState data la date de naissance et l'age
  function handleDateChange(newValue) {
    const date = dayjs(newValue);

    const year = date.year();
    const month = date.month();
    const day = date.date();

    const age = AgeCalculator(day, month, year);
    const oui = newValue.toString();
    //useState pour l'age
    setData({ ...data, date: oui, age: age });
  }

  return (
    <>
      {/* <Grid item xs={12}>
              <SelectGender />
            </Grid> */}

      <Grid item xs={12}>
        <Typography variant="h5">Vous</Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          value={data.name || ""}
          id="name"
          label="Nom"
          variant="outlined"
          onChange={(e) => setData({ ...data, name: e.target.value })}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          value={data.surname || ""}
          id="surname"
          label="Prénom"
          variant="outlined"
          onChange={(e) => setData({ ...data, surname: e.target.value })}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"fr"}>
          <DatePicker
            label="Date de naissance"
            value={dayjs(data.date) || ""}
            onChange={handleDateChange}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          value={data.birthPlace || ""}
          id="birthPlace"
          label="Lieu de Naissance"
          variant="outlined"
          onChange={(e) => setData({ ...data, birthPlace: e.target.value })}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          value={data.occupation || ""}
          id="surname"
          label="Profession"
          variant="outlined"
          onChange={(e) => setData({ ...data, occupation: e.target.value })}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          value={data.localization || ""}
          id="localization"
          label="Adresse"
          variant="outlined"
          onChange={(e) => setData({ ...data, localization: e.target.value })}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          value={data.phone || ""}
          id="phone"
          label="Numéro de Téléphone"
          variant="outlined"
          onChange={(e) => setData({ ...data, phone: e.target.value })}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          value={data.mail || ""}
          id="mail"
          label="Adresse Mail"
          variant="outlined"
          onChange={(e) => setData({ ...data, mail: e.target.value })}
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <SelectMaritalStatus
          maritalStatus={data.maritalStatus}
          setMaritalStatus={(e) =>
            setData({ ...data, maritalStatus: e.target.value })
          }
        />
      </Grid>

      {data.maritalStatus === "mariage" && (
        <Grid item xs={12} md={4}>
          <MaritalContract
            data={data}
            setData={(e) =>
              setData({ ...data, maritalContract: e.target.value })
            }
          />
        </Grid>
      )}

      <Grid item xs={12}>
        <SelectIsChild
          value={data.isChild}
          handleChange={(e) => setData({ ...data, isChild: e.target.value })}
        />
      </Grid>
    </>
  );
}
