import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

export default function SelectTypeEstate({ data, setData }) {
  return (
    <>
      <FormControl sx={{ m: 0, width: "100%" }}>
        <InputLabel id="select-type-estate-label">Type</InputLabel>
        <Select
          labelId="select-type-estate-label"
          id="select-type-estate"
          name="type"
          value={data.type || ""}
          label="Type"
          autoWidth
          onChange={setData}
        >
          <MenuItem value={"Résidence Principale"}>Résidence Principale</MenuItem>
          <MenuItem value={"Bien d'Usage"}>
            Bien d'usage (residence secondaire)
          </MenuItem>
          <MenuItem value={"Bien Locatif"}>Bien locatif</MenuItem>
          <MenuItem value={"Investissement Immobilier"}>Investissement Immobilier</MenuItem>
          <MenuItem value={"Autre"}>Autre</MenuItem>
        </Select>
      </FormControl>
    </>
  );
}
