import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

export default function SelectDetentionMode({ data, setData }) {
  return (
    <>
      <FormControl sx={{ m: 0, width: "100%" }}>
        <InputLabel id="select-detention-mode-label">Detention</InputLabel>
        <Select
          labelId="select-detention-mode-label"
          id="select-detention-mode"
          name="detentionMode"
          value={data.detentionMode || ""}
          label="Detention"
          autoWidth
          onChange={setData}
        >
          <MenuItem value={"pp"}>Pleine Propriété</MenuItem>
          <MenuItem value={"uf"}>Usufruit</MenuItem>
          <MenuItem value={"np"}>Nue-Propriété</MenuItem>
          <MenuItem value={"joint"}>Indivision</MenuItem>
        </Select>
      </FormControl>
    </>
  );
}
