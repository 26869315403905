import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import { useState } from "react";

export default function DialogConseil({ open, handleClose, totalPatrim }) {
  const patrimData = localStorage.getItem("patrimData");
  const patrimDataArray = patrimData !== null ? JSON.parse(patrimData) : [];

  const [isConseil, setIsConseil] = useState(false);

  function Conseil() {
    let conseils = <Typography>oui</Typography>;

    if (totalPatrim < 200000) {
      conseils = (
        <Typography>
          Vous n'avez pas besoin d'optimiser votre succession
        </Typography>
      );
    } else {
      setIsConseil(true);
      conseils = patrimDataArray.map((object) => {
        if (object.name === "cash" && parseInt(object.valeur) > 40000) {
          return (
            <Typography>
              Vous avez beaucoup de liquidités, il pourrait être intéressant de
              souscrire à une assurance-vie
            </Typography>
          );
        } else if (
          object.name === "estate" &&
          parseInt(object.valeur) > 100000
        ) {
          return (
            <Typography sx={{ mt: 4 }}>
              Vous avez beaucoup d'immobilier, vous pouvez peut être penser au
              démembrement
            </Typography>
          );
        } else {
          return null; // Retourne null si aucun conseil n'est applicable
        }
      });
    }
    return <div>{conseils}</div>;
  }

  const handleButtonClick = () => {
    window.open("https://isonos.fr/contact/", "_blank");
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Conseil Successoral"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Conseil />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {isConseil && (
            <Button onClick={handleButtonClick}>Prendre RDV</Button>
          )}

          <Button onClick={(e) => console.log(totalPatrim)}>Log</Button>
          <Button onClick={handleClose} autoFocus>
            Fermer
          </Button>
          {/* <a href="https://www.isonos.fr" target="_blank">
                    En savoir plus
            </a> */}
        </DialogActions>
      </Dialog>
    </>
  );
}
