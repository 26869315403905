import { Toolbar, Typography } from "@mui/material";

import ExpenseInput from "../../../components/input/expense-input/expenseInput";

export default function AddExpense() {
  return (
    <>
      <Toolbar />
      <ExpenseInput />
    </>
  );
}
