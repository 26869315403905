import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
// import { AppBar } from "@mui/material";
import { useState } from "react";
import MainDrawer from "./drawer/mainDrawer";
import MenuIcon from "@mui/icons-material/Menu";
import { useAuth } from "../utils/firestore-database/providers/authProvider";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Avatar, Menu, MenuItem, Box, ListItemIcon } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";

const drawerWidth = 300;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

// C'est le flexgrow de gros FDP QUI FAIT QUE CA VA A DROITE BORDEL
export default function MainAppBar() {
  const { currentUser, Logout } = useAuth();

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  //Le context de Auth

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  function ClearStorage() {
    localStorage.clear();
    console.log(localStorage);
  }

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);

    console.log(event.currentTarget);
    console.log(anchorEl);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogoutClick = () => {
    {
      Logout;
    }
  };

  return (
    // <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
    //On wrap le tout dans une toolbar pour que ce soit cote a cote, sinon appbar seul c'est dessus dessous
    <>
      <AppBar position="absolute" open={open}>
        <Toolbar>
          {currentUser && (
            <>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{ mr: 2, ...(open && { display: "none" }) }}
              >
                <MenuIcon />
              </IconButton>
            </>
          )}

          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1 }}
          ></Typography>
          {/* <Button component={Link} to={"customerCifDocument"}>
            La bas
          </Button> */}

          <IconButton
            size="large"
            sx={{ backgroundColor: (theme) => theme.palette.grey[300] }}
            onClick={handleMenuOpen}
          >
            <AccountCircleIcon fontSize="inherit" />
          </IconButton>

          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={Logout}>
              <ListItemIcon>
                <LogoutIcon fontSize="small" />
              </ListItemIcon>
              Se déconnecter
            </MenuItem>
          </Menu>
          {/* <Typography>{JSON.stringify(anchorEl)}</Typography> */}

          {/* <Button onClick={Logout}> LogOUT</Button> */}
        </Toolbar>
      </AppBar>
      <MainDrawer open={open} handleDrawerClose={handleDrawerClose} />
    </>
  );
}
