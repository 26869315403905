import { useState, Fragment } from "react";
import {
  Button,
  TextField,
  Grid,
  Toolbar,
  CircularProgress,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { useData } from "../../../utils/firestore-database/providers/dataProvider";
import AgeCalculator from "../../../utils/calculator/ageCalulator";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { child } from "firebase/database";

export default function SetChild({ data, setData }) {
  // const { personnalData, personnalDataLoading } = useData();

  // const [data, setData] = useState([]);
  const [checked, setIsChecked] = useState(false);
  const [childData, setChildData] = useState(data.child || []);

  useEffect(() => {
    data.child && setChildData(data.child);
  }, [data]);

  const handleClick = () => {
    setChildData([...childData, {}]);

    // const childus = childData.length + 1;

    // setData({ ...data, childNumber: childus });
  };

  // const handleClick = () => {
  //   // Ajouter un nouvel objet vide à childData
  //   setData({ ...data, child: [...data.child, {}] });

  //   // // Calculer le nouveau numéro d'enfant
  //   // const childNumber = childData.length + 1;

  //   // // Ajouter une nouvelle entrée à data avec childNumber
  //   // setData({ ...data, childNumber: childNumber });
  // };

  const handleChange = (e, i) => {
    const { name, value } = e.target;

    const onchangeVal = [...childData];

    onchangeVal[i][name] = value;

    const childNumber = childData.length;

    setData((prevData) => ({
      ...prevData,
      child: onchangeVal,
      childNumber: childNumber,
    }));
  };

  const handleDelete = (i) => {
    const deleteVal = [...childData];
    deleteVal.splice(i, 1);
    setChildData(deleteVal);
  };

  function handleDateChange(e, index) {

    const date = dayjs(e);

    const year = date.year();
    const month = date.month();
    const day = date.date();

    const dateString = e.toString();

    const age = AgeCalculator(day, month, year);

    const onchangeVal = [...childData];

    onchangeVal[index]["date"] = dateString;


    const onchangeVal2 = [...childData];

    onchangeVal2[index]["age"] = age;

    // setData((prevData) => ({
    //   ...prevData,
    //   child: onchangeVal2,
    //   // onchangeVal2,
    // }));
    // console.log('Updated state:', onchangeVal);
  }

  function handleCheckedChange(e, index) {
    const onChangeVal = [...childData];
    onChangeVal[index]["dependant"] = e.target.checked;

    setData((prevData) => ({
      ...prevData,
      child: onChangeVal,
    }));
  }

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5">Vos Enfants</Typography>
      </Grid>
      {childData.map((item, index) => (
        <Fragment key={`fragment ${index}`}>
          <Grid item xs={12} md={3}>
            <TextField
              required
              name="name"
              value={item.name || ""}
              id={`child ${index}`}
              label="Nom de votre enfant"
              variant="outlined"
              onChange={(e) => handleChange(e, index)}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              required
              name="surname"
              value={item.surname || ""}
              id="surname"
              label="Prénom de votre Enfant"
              variant="outlined"
              onChange={(e) => handleChange(e, index)}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale={"fr"}
            >
              <DatePicker
                label="Date de naissance"
                value={dayjs(item.date) || ""}
                onChange={(e) => handleDateChange(e, index)}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={2}>
            {/* <DependantChild /> */}
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={item.dependant || false}
                    onChange={(e) => handleCheckedChange(e, index)}
                  />
                }
                label="A charge"
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12} md={1}>
            <Button variant="outlined" onClick={() => handleDelete(index)}>
              X
            </Button>
          </Grid>
        </Fragment>
      ))}
      <Grid item xs={12}>
        <Button variant="outlined" onClick={handleClick}>
          ajouter un enfant
        </Button>
      </Grid>
    </>
  );
}
