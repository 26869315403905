import { Button } from "@mui/material";
import jsPDF from "jspdf";
import { useEffect, useState } from "react";




export default function RelationEntryPdfCreation({ signatureImageUrl, setIsSigned, isSigned }) {


    const [pdfData, setPdfData] = useState("")
    const pdfDoc = new jsPDF();




    function dataToPdf() {

        const déroulement =

            pdfDoc.setFontSize(12)
        pdfDoc.text(`
                Les informations recueillies sur ce formulaire sont enregistrées dans un fichier 
        informatisé par Isonos pour le bon de notre mission de CGP
        Elles sont conservées pendant 10 ans et sont destinées à Isonos
        Conformément à la loi « informatique et libertés », vous pouvez exercer votre 
        droit d’accès, d’opposition, d’effacement, de limitation du traitement, de portabilité des données 
        vous concernant et les faire rectifier en contactant : Isonos a contact@isonos.fr
        Vous pouvez également introduire une réclamation au sujet du traitement de vos données 
        auprès de la CNIL.`, 5, 20);

        pdfDoc.text("Signature", 150, 70, null, null, "right");
        pdfDoc.addImage(signatureImageUrl, "JPEG", 150, 70, 20, 20, "right");




    }

    function embedPdf() {

        dataToPdf()

        const pdfBlob = pdfDoc.output('blob')
        const pdfURL = URL.createObjectURL(pdfBlob)
        setPdfData(pdfURL)

    }

    function download() {

        dataToPdf()
        pdfDoc.save('DER.pdf')
    }

    useEffect(() => {
        embedPdf()
    }, [])

    return (
        <>
            {/* <Button onClick={dataToPdf}>data To PDF</Button> */}
            {pdfData ?
                <>
                    <embed
                        src={pdfData}
                        width="100%"
                        height="750px"
                        type="application/pdf"
                    />
                    <Button onClick={() => setIsSigned(false)}>Annuler</Button>
                    <Button onClick={download}>Sauvegarder</Button>
                </>
                :
                <p>Loading PDF...</p>
            }
        </>
    )
}