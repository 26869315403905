// import './App.css';
import {
  Button,
  Typography,
  Input,
  TextField,
  Stack,
  Paper,
  Grid,
  Toolbar,
  Box,
} from "@mui/material";
import dayjs from "dayjs";
import { Link, useNavigate } from "react-router-dom";
import { useData } from "../utils/firestore-database/providers/dataProvider";
import SumChart from "../components/charts/charts/sumChart";
import MainAppBar from "../components/mainAppBar";
import { useEffect } from "react";
import { useAuth } from "../utils/firestore-database/providers/authProvider";

function FirstArrival() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // minHeight: "100vh",
        flexDirection: "column",
        backgroundImage: "linear-gradient(180deg, #CEE5FD, #FFF)",
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
      }}
    >
      <Typography
        variant="h2"
        sx={{
          // display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignSelf: "center",
          // textAlign: "center",
          fontSize: "clamp(3.5rem, 10vw, 4rem)",
          fontFamily: "Roboto, sans-serif",
        }}
      >
        BIENVENUE SUR 3-6 PAT
      </Typography>
      <Typography variant="h4" sx={{ mt: 1, alignSelf: "center" }}>
        Votre application de gestion de patrimoine
      </Typography>
      {/* <Paper sx={{ mt: 2 }}> */}

      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          p: 2,
          m: 2,
          maxWidth: 900,
          width: "100%",
        }}
      >
        <Stack spacing={2}>
          {/* <Typography variant="h5" gutterBottom>
            Pour commencer
          </Typography> */}

          <Button
            variant="contained"
            component={Link}
            to="patrimoine"
            sx={{ padding: "10px 30px" }}
          >
            Votre patrimoine
          </Button>

          <Button
            variant="contained"
            component={Link}
            to="income"
            sx={{ padding: "10px 30px" }}
          >
            Vos revenus
          </Button>

          <Button
            variant="contained"
            component={Link}
            to="expense"
            sx={{ padding: "10px 30px" }}
          >
            Vos charges
          </Button>

          <Button
            variant="contained"
            component={Link}
            to="infospersos"
            sx={{ padding: "10px 30px" }}
          >
            Vos informations personnelles
          </Button>
        </Stack>
      </Paper>

      {/* </Paper> */}
    </Box>
  );
}

function DashBoard({ sumData }) {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={8} lg={6}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: 300,
          }}
        >
          <SumChart data={sumData} />
        </Paper>
      </Grid>
    </Grid>
  );
}

function Home() {
  const { currentUser } = useAuth();
  const { sumData } = useData();
  // ça c'est pour la navigation mais on dirait que y a mieux à faire avec redirect dans un loader de react router ou jsais pas quoi https://reactrouter.com/en/main/fetch/redirect
  const navigate = useNavigate();

  useEffect(() => {
    if (!currentUser) {
      navigate("/signIn");
    }
  }, [currentUser, navigate]);

  return (
    <>
      {!sumData ? (
        <>
          <FirstArrival />
          <Typography>{JSON.stringify(sumData)}</Typography>
        </>
      ) : (
        // <DashBoard sumData={sumData} />

        <Box sx={{ display: "flex" }}>
          <MainAppBar />
          <Box
            component="main"
            sx={{
              // backgroundImage: "linear-gradient(180deg, #CEE5FD, #FFF)",

              backgroundColor: (theme) =>
                theme.palette.mode === "light"
                  ? theme.palette.grey[300]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: "100vh",
              overflow: "auto",
            }}
          >
            <FirstArrival />
          </Box>
        </Box>
      )}
    </>
  );
}

export default Home;
