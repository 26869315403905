import { Button, Box, Tabs, Tab, Toolbar, Typography } from "@mui/material";

import { useState } from "react";
import GlobalPatrimoineDisplay from "../components/display/patrimoine-display/globalPatrimoineDisplay";
import { Link } from "react-router-dom";
import DetailPatrimoineDisplay from "../components/display/patrimoine-display/detailPatrimoineDisplay";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

// ça je crois que c'est pour l'accessibilité avec le logiciel qui lit les trucs pour les gens aveugles
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Patrimoine() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Typography variant="h4">VOTRE PATRIMOINE</Typography>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            centered
          >
            <Tab label="Patrimoine Global" {...a11yProps(0)} />
            <Tab label="Détail" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <Toolbar />
          <GlobalPatrimoineDisplay />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <DetailPatrimoineDisplay />
        </CustomTabPanel>
      </Box>
    </>
  );
}
