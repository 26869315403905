import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
  ResponsiveContainer,
  Rectangle,
} from "recharts";
import { Button } from "@mui/material";
import dataWithOtherTransformed from "../../../utils/transform-data/dataWithOtherTransformed";
import { blue } from "@mui/material/colors";

export default function IncomeChart({ data }) {

  return (
    <>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          // width={700}
          // height={500}
          data={dataWithOtherTransformed(data)}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="type" />
          <YAxis />
          <Tooltip />
          {/* <Legend /> */}
          <Bar
            dataKey="amount"
            fill="#8884d8"
            activeBar={<Rectangle fill={blue[100]} stroke="blue" />}
          />
        </BarChart>
      </ResponsiveContainer>
    </>
  );
}
