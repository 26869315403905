import { FormControl, InputLabel, Select, MenuItem } from "@mui/material"

export default function SelectAddFinancial({ data, setData }) {
    return (
        <>
            <FormControl sx={{ m: 0, width: '100%' }}>
                <InputLabel id="select-financial-label">Type</InputLabel>
                <Select
                    labelId="select-financial-label"
                    id="select-financial"
                    name="type"
                    value={data.type || ""}
                    label="Type"
                    autoWidth
                    onChange={setData}>
                    <MenuItem value={'Compte Titre Ordinaire'}>
                        Compte Titre Ordinaire
                    </MenuItem>
                    <MenuItem value={'PEA'}>
                        PEA
                    </MenuItem>
                    <MenuItem value={"PEA PME"}>
                        PEA PME
                    </MenuItem>
                    <MenuItem value={"Assurance Vie"}>
                        Assurance Vie
                    </MenuItem>
                    <MenuItem value={"Autre"}>
                        Autre
                    </MenuItem>
                </Select>
            </FormControl>
        </>
    )
}